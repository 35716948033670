import React, { Component } from 'react';


class Logo extends Component {
    render() {
        return (
            <svg width="180px" height="60px" viewBox="0 0 322 92" style={{'userSelect': 'none'}}>
                <g>
                    <path d="M28,36.1L16.8,31c-0.5-0.2-1.1,0-1.3,0.5c-0.2,0.5,0,1.1,0.5,1.3l10.7,4.9v19.1l-10.7,4.5c-0.5,0.2-0.8,0.8-0.5,1.3   c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1L28,58.4c0.4-0.2,0.6-0.5,0.6-0.9V37C28.6,36.6,28.4,36.3,28,36.1z"></path>
                    <path d="M18.5,57.3V37c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v20.3c0,0.2,0.2,0.4,0.4,0.4S18.5,57.5,18.5,57.3z"></path>
                    <path d="M22.4,53.6V40.7c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v12.9c0,0.2,0.2,0.4,0.4,0.4S22.4,53.8,22.4,53.6z"></path>
                    <path d="M84.8,31.4c-0.2-0.5-0.8-0.7-1.3-0.5l-11.3,5.2c-0.4,0.2-0.6,0.5-0.6,0.9v20.4c0,0.4,0.2,0.8,0.6,0.9l11.3,4.7   c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.8-0.2,0.9-0.6c0.2-0.5,0-1.1-0.5-1.3l-10.7-4.5V37.7l10.7-4.9C84.8,32.5,85,31.9,84.8,31.4z"></path>
                    <path d="M81.7,37v20.3c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4V37c0-0.2-0.2-0.4-0.4-0.4S81.7,36.8,81.7,37z"></path>
                    <path d="M77.9,40.7v12.9c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4V40.7c0-0.2-0.2-0.4-0.4-0.4S77.9,40.5,77.9,40.7z"></path>
                    <path d="M13.5,82.9c0,0.5,0.5,1,1,1c0,0,0,0,0,0c0.6,0,1-0.5,1-1c0-0.4-0.2-7.8,5.2-12.5c-0.7-0.2-1.4-0.5-2.1-0.7   C13.3,75,13.5,82.8,13.5,82.9z"></path>
                    <path d="M79.3,70.3c5.3,4.8,5.2,12.1,5.2,12.5c0,0.6,0.4,1,1,1c0,0,0,0,0,0c0.5,0,1-0.4,1-1c0-0.1,0.2-7.8-5.1-13.3   C80.7,69.8,80,70.1,79.3,70.3z"></path>
                    <path d="M61.6,22c-2.7-3.5-6.9-5.8-11.6-5.8c-4.7,0-9,2.3-11.6,5.8c-14,1.1-26,4.3-26,9.2v1.3v30.3c0,2.4,2.8,4.3,7.2,5.9   c0.7,0.2,1.5,0.5,2.3,0.7c7.3,2.1,17.9,3.1,28.2,3.1c10.2,0,20.8-1.1,28.1-3.1c0.8-0.2,1.6-0.5,2.3-0.7c4.5-1.5,7.2-3.5,7.2-5.9   V32.5v-1.3C87.7,26.2,75.6,23.1,61.6,22z M50,18.2c3.4,0,6.5,1.4,8.8,3.6c-2.9-0.2-5.9-0.3-8.8-0.3c-2.9,0-5.9,0.1-8.8,0.3   C43.5,19.5,46.6,18.2,50,18.2z M50,70.5c-10.8,0-19.6-1.1-25.7-2.5c1.4-0.6,2.9-1.1,4.7-1.4c11.5-1.8,14.1-7.4,14.6-10.4   c1.9,1.3,4,2.1,6.4,2.1c2.3,0,4.5-0.8,6.4-2.1c0.5,3,3.1,8.5,14.6,10.4c1.8,0.3,3.4,0.8,4.8,1.4C69.5,69.4,60.8,70.5,50,70.5z    M39.9,37.1c-0.1-0.2-0.3-0.2-0.5-0.1l-4.9,3v-1.1l5.3-3.3c0.2-0.1,0.2-0.3,0.1-0.5c-0.1-0.2-0.3-0.2-0.5-0.1l-4.9,3v-5.9   c0-0.7,0.6-1.2,1.2-1.2h0.6h27.2h0.6c0.7,0,1.2,0.6,1.2,1.2v10.2c0,0.7-0.5,1.2-1.2,1.2H63h-7.8L53.1,40c-0.7-1.1-1.8-1.8-3.1-1.8   c-1.3,0-2.5,0.7-3.1,1.8l-2.1,3.6H37h-1.3c-0.7,0-1.2-0.6-1.2-1.2v-1.4l5.3-3.3C40,37.5,40.1,37.3,39.9,37.1z M45.4,45.6   c0.4,0,0.7-0.2,0.9-0.6c0,0,0-0.1,0-0.1l2.2-3.9c0.3-0.5,0.8-0.8,1.4-0.8s1.1,0.3,1.4,0.8l2.2,3.8c0,0,0.1,0.2,0.1,0.2   c0.2,0.4,0.5,0.6,0.9,0.6h7.1C59.7,51,55.2,56.3,50,56.3c-5.2,0-9.7-5.3-11.6-10.8H45.4z M85.7,32.5v30.3c0,1.4-2.5,3-7.2,4.4   c-1.9-1.2-4.3-2.2-7.2-2.6c-12.8-2-13.1-8.7-13-9.9c2.5-2.4,4.4-5.7,5.5-9.1h0.5c1.8,0,3.2-1.4,3.2-3.2v-1.4h0.6c0.6,0,1-0.4,1-1   v-4.4c0-0.6-0.4-1-1-1h-0.6v-2.4c0-1.7-1.3-3.1-3-3.2c-0.2-1.2-0.5-2.4-0.9-3.6c-0.7-0.1-1.5-0.1-2.3-0.2c0.6,1.2,1,2.4,1.2,3.7   h-25c0.2-1.3,0.6-2.6,1.2-3.7c-0.8,0.1-1.5,0.1-2.3,0.2c-0.5,1.1-0.8,2.3-0.9,3.6c-1.7,0.1-3,1.5-3,3.2v2.4h-0.6c-0.6,0-1,0.4-1,1   v4.4c0,0.6,0.4,1,1,1h0.6v1.4c0,1.8,1.4,3.2,3.2,3.2h0.5c1.1,3.4,3,6.7,5.5,9.1c0,1.1-0.3,7.9-13,9.9c-2.9,0.5-5.3,1.4-7.2,2.6   c-4.6-1.4-7.2-3-7.2-4.4V32.5v-1.3c0-2.5,8.4-5.8,22.7-7.1c0.8-0.1,1.6-0.1,2.4-0.2c3.3-0.2,6.8-0.4,10.6-0.4   c3.8,0,7.3,0.1,10.5,0.4c0.8,0.1,1.6,0.1,2.4,0.2c14.3,1.3,22.7,4.6,22.7,7.2V32.5z"></path>
                </g>
                <text x="100" y="63" fontSize="38" fontFamily="Arvo" fill="black">Interio[V]R</text>
            </svg>
        );
    }
}

export default Logo;
