import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setUserInfo } from '../actions';
import { getUserInfo } from '../api';

class AuthCheck extends Component {

    static propTypes = {
        children: PropTypes.node,
        required: PropTypes.bool,
        user: PropTypes.object,
        setUserInfo: PropTypes.func
    };

    componentDidMount() {
        if (!this.props.user) {
            getUserInfo().then(this.props.setUserInfo, e => console.warn('No logged in user', e));
        }
    }

    render() {
        if (this.props.required && !this.props.user) {
            return <div>{'Unauthorized'}</div>;
        }
        return this.props.children;
    }
}


export default connect(
    state => ({
        user: state.auth.user,
    }),
    {
        setUserInfo
    }
)(AuthCheck);
