import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Input, Table, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { appendObject, setObjects } from '../actions';
import { addObject, listObjects } from '../api';
import MenuBar from './MenuBar';
import { loadFile } from '../utils';

class ObjectsPage extends Component {
    static propTypes = {
        setObjects: PropTypes.func.isRequired,
        appendObject: PropTypes.func.isRequired,
        objects: PropTypes.array,
    };

    state = {
        objFiles: null,
        mtlFiles: null,
    };

    componentDidMount() {
        if (!this.props.objects) {
            listObjects().then(this.props.setObjects);
        }
    }

    handleChangeObj = e => {
        this.setState({ objFiles: e.target.files });
    };

    handleChangeMtl = e => {
        this.setState({ mtlFiles: e.target.files });
    };

    handleUpload = async () => {
        const [obj, mtl] = await Promise.all([
            loadFile(this.state.objFiles[0]),
            loadFile(this.state.mtlFiles[0]),
        ]);
        const nameWithoutExtension = (/^(.+)\.[^.]+$/).exec(this.state.objFiles[0].name)[1];
        addObject(nameWithoutExtension, obj, mtl)
            .then(this.props.appendObject)
            .then(() => this.setState({ objFiles: null, mtlFiles: null }));
    };

    render() {
        // todo(interiovr): allow renaming objects (see updateObjectMeta in api.js)
        // todo(interiovr): allow overwriting the .obj file of an object (see updateObjectObj in api.js)
        // todo(interiovr): allow overwriting the .mtl file of an object (see updateObjectMtl in api.js)
        // todo(interiovr): allow removing objects (see removeObject in api.js)
        return (
            <div className="ObjectsPage">
                <Helmet>
                    <title>Objects | Interio[V]R</title>
                </Helmet>
                <MenuBar/>
                <Table>
                    <Table.Header>
                        <Table.Row textAlign="center">
                            <Table.HeaderCell>
                                {'Objects'}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(this.props.objects || []).map(({ name, object_id }) => (
                            <Table.Row key={object_id}>
                                <Table.Cell>
                                    {name}
                                    {' '}
                                    <a download={`${name}.obj`} href={`/api/objects/${object_id}/obj`}>{'.obj'}</a>
                                    {' '}
                                    <a download={`${name}.mtl`} href={`/api/objects/${object_id}/mtl`}>{'.mtl'}</a>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Menu floated>
                                    <Menu.Item>
                                        <Input
                                            type="file"
                                            onChange={this.handleChangeObj}
                                            label=".obj"
                                            accept=".obj"
                                        />
                                        <Input
                                            type="file"
                                            onChange={this.handleChangeMtl}
                                            label=".mtl"
                                            accept=".mtl"
                                        />
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={this.handleUpload}
                                        disabled={!this.state.objFiles || !this.state.mtlFiles}
                                    >
                                        {'Upload'}
                                    </Menu.Item>
                                </Menu>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        );
    }
}

export default connect(
    state => ({
        objects: state.objects.objects,
    }),
    {
        setObjects,
        appendObject,
    }
)(ObjectsPage);
