import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import Logo from './Logo';
import { signupPassword } from '../api';

import './SignUpPage.css';


class SignUpPage extends Component {
    static propTypes = {
        history: PropTypes.object,
    };

    state = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    handleSignup = () => {
        signupPassword(this.state.name, this.state.email, this.state.password)
            .then(() => this.props.history.push('/'));
    };

    render() {
        const isPasswordWrong = this.state.confirmPassword !== this.state.password;
        return (
            <div className="SignUpPage">
                <Helmet>
                    <title>Sign Up | Interio[V]R</title>
                </Helmet>
                <Segment>
                    <Logo />
                    <Form onSubmit={this.handleSignup}>
                        <Form.Input name="name" label="Name" onChange={this.handleChange} autoFocus/>
                        <Form.Input name="email" label="Email" onChange={this.handleChange}/>
                        <Form.Input
                            name="password"
                            label="Password"
                            type="password"
                            onChange={this.handleChange}
                        />
                        <Form.Input
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            error={isPasswordWrong}
                            onChange={this.handleChange}
                        />
                        <Button primary fluid type='submit' disabled={isPasswordWrong}>
                            Sign Up
                        </Button>
                    </Form>
                </Segment>
            </div>
        );
    }
}

export default SignUpPage;
