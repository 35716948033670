export function loadFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsText(file);
    });
}

export function updateIndex(array, i, fn) {
    return [
        ...array.slice(0, i),
        fn(array[i]),
        ...array.slice(i + 1),
    ];
}
