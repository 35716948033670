import React, { Component } from 'react';
import { Image, Button, Grid } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import MenuBar from './MenuBar';
import lady from '../assets/images/vive_lady.png';
import './LandingPage.css';


class LandingPage extends Component {

    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object
    };

    render() {
        return (
            <div className="LandingPage">
                <Helmet>
                    <title>Interio[V]R</title>
                </Helmet>
                <MenuBar />
                <Grid divided="vertically">
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Image src={lady} style={{'paddingLeft': '50px'}}/>
                        </Grid.Column>
                        <Grid.Column className="intro">
                            <h1>Interior Design.<br />Re-imagined.</h1>
                            <h3>A beautiful interior design requires a creative mind and the right software. Interio[V]R is a platform to plan and design interior spaces, giving you complete autonomy in choosing structural specifications, furniture, and decorations, as well as visualizing your design through virtual reality.</h3>
                            <br />
                            <Button primary size="huge">
                                <Link to="/signup">
                                    Get Started
                                </Link>
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(LandingPage);
