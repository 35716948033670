import {
    Models as PlannerModels,
    reducer as PlannerReducer,
    Actions as PlannerActions
} from './vendor/react-planner';

const auth = (
    state = {
        user: null,
    },
    action = {}
) => {
    switch (action.type) {
    case 'LOGOUT':
        return {
            ...state,
            user: null,
        };
    case 'SET_USER_INFO':
        return {
            ...state,
            user: action.info,
        };
    default:
        return state;
    }
};

const objects = (
    state = {
        objects: null,
        obj: {},
        mtl: {},
    },
    action = {}
) => {
    switch (action.type) {
    case 'LOGOUT':
        return {
            ...state,
            objects: null,
            obj: {},
            mtl: {},
        };
    case 'SET_OBJECTS':
        return {
            ...state,
            objects: action.objects,
        };
    case 'APPEND_OBJECT':
        return {
            ...state,
            objects: [...state.objects, action.object],
        };
    case 'SET_OBJECT_OBJ':
        return {
            ...state,
            obj: {
                ...state.obj,
                [action.id]: action.obj,
            },
        };
    case 'SET_OBJECT_MTL':
        return {
            ...state,
            mtl: {
                ...state.mtl,
                [action.id]: action.mtl,
            },
        };
    default:
        return state;
    }
};

const designs = (
    state = {
        designs: null,
        json: {},
    },
    action = {}
) => {
    switch (action.type) {
    case 'LOGOUT':
        return {
            ...state,
            designs: null,
            json: {},
        };
    case 'SET_DESIGNS':
        return {
            ...state,
            designs: action.designs
        };
    case 'APPEND_DESIGN':
        return {
            ...state,
            designs: [...state.designs, action.design],
        };
    case 'DELETE_DESIGN':
        state.designs.splice(state.designs.findIndex(design => design.design_id === action.design), 1);
        return {
            ...state,
            designs: [...state.designs],
        };
    case 'SET_DESIGN_JSON':
        return {
            ...state,
            json: {
                ...state.json,
                [action.id]: action.json,
            }
        };
    default:
        return state;
    }
};

const floorplanner = (
    state = new PlannerModels.State(),
    action = {}
) => {
    switch (action.type) {
    case 'LOGOUT':
        return new PlannerModels.State();
    case 'LOAD_WALLS_INTO_PLANNER_STATE':
        return PlannerReducer(state, PlannerActions.projectActions.loadProject(action.walls));
    default:
        return PlannerReducer(state, action);
    }
};


export default { auth, objects, floorplanner, designs };
