import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { logout } from '../actions';
import { signout } from '../api';


class SignOutPage extends Component {

    static propTypes = {
        history: PropTypes.object,
        logout: PropTypes.func
    };

    componentDidMount() {
        signout()
            .then(this.props.logout)
            .then(() => this.props.history.push('/'));
    }

    render() {
        return (<div></div>);
    }
}

export default connect(
    null,
    {
        logout
    }
)(SignOutPage);
