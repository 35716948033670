export const setUserInfo = ({ name }) => ({
    type: 'SET_USER_INFO',
    info: { name },
});

export const setObjects = (objects) => ({
    type: 'SET_OBJECTS',
    objects,
});

export const appendObject = (object) => ({
    type: 'APPEND_OBJECT',
    object,
});

export const setObjectObj = (id, obj) => ({
    type: 'SET_OBJECT_OBJ',
    id,
    obj,
});

export const setObjectMtl = (id, mtl) => ({
    type: 'SET_OBJECT_MTL',
    id,
    mtl,
});

export const setDesigns = (designs) => ({
    type: 'SET_DESIGNS',
    designs,
});

export const appendDesign = (design) => ({
    type: 'APPEND_DESIGN',
    design,
});

export const deleteDesign = (design) => ({
    type: 'DELETE_DESIGN',
    design,  
});

export const setDesignJson = (id, json) => ({
    type: 'SET_DESIGN_JSON',
    id,
    json
});

export const loadWallsIntoPlannerState = (walls) => ({
    type: 'LOAD_WALLS_INTO_PLANNER_STATE',
    walls,
});

export const logout = () => ({
    type: 'LOGOUT',
});
