import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setDesignJson } from '../actions';
import { getDesignJson } from '../api';

class LoadDesignInfo extends Component {

    static propTypes = {
        children: PropTypes.node,
        match: PropTypes.object,
        designJson: PropTypes.object,
        setDesignJson: PropTypes.func
    };

    componentDidMount() {
        if (!this.props.designJson[this.props.match.params.id]) {
            getDesignJson(this.props.match.params.id)
                .then(json => this.props.setDesignJson(this.props.match.params.id, json));
        }
    }

    render() {
        return this.props.designJson[this.props.match.params.id] ? this.props.children : <div/>;
    }
}


export default connect(
    state => ({
        designJson: state.designs.json,
    }),
    {
        setDesignJson
    }
)(LoadDesignInfo);
