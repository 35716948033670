import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Divider, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Logo from './Logo';
import { signinPassword } from '../api';

import './SignInPage.css';


class SignInPage extends Component {
    static propTypes = {
        history: PropTypes.object,
    };

    state = {
        email: '',
        password: '',
    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    handleSignin = () => {
        signinPassword(this.state.email, this.state.password)
            .then(() => this.props.history.push('/'));
    };

    render() {
        return (
            <div className="SignInPage">
                <Helmet>
                    <title>Sign In | Interio[V]R</title>
                </Helmet>
                <Segment>
                    <Logo />
                    <Form onSubmit={this.handleSignin}>
                        <Form.Input name="email" label="Email" onChange={this.handleChange} autoFocus/>
                        <Form.Input name="password" label="Password" type="password" onChange={this.handleChange}/>
                        <Button primary fluid type="submit">
                            Sign In
                        </Button>
                    </Form>
                    <Divider horizontal>Or</Divider>
                    <div className="createAccountText">
                        No account?
                        <Link to="/signup"> Create one</Link>.
                    </div>
                </Segment>
            </div>
        );
    }
}

export default SignInPage;
