import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import InteriorEditingScene from './InteriorEditingScene';

import './WorkspacePage.css';


class WorkspacePage extends Component {
    static propTypes = {
        match: PropTypes.object,
    };

    render() {
        return (
            <div className="WorkspacePage">
                <Helmet>
                    <title>Workspace | Interio[V]R</title>
                </Helmet>
                <InteriorEditingScene match={this.props.match}/>
            </div>
        );
    }
}

export default WorkspacePage;
