export const DEMO_JSON = {
    walls: {
        'unit': 'cm',
        'layers': {
            'layer-1': {
                'id': 'layer-1',
                'altitude': 0,
                'order': 0,
                'opacity': 1,
                'name': 'default',
                'visible': true,
                'vertices': {
                    'SJfgCDjs-XQ': {
                        'id': 'SJfgCDjs-XQ',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': 100,
                        'y': 390,
                        'lines': ['SyHxRDii-77', 'BkadiobQ7', 'SJlHtsoW77'],
                        'areas': ['HJ8gAvoobX7', 'SkPlCPosb7Q', 'rkdl0wji-Xm']
                    },
                    'SymUcCKb7X': {
                        'id': 'SymUcCKb7X',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': -290,
                        'y': 390,
                        'lines': ['BkHFji-Xm', 'SJlHtsoW77'],
                        'areas': ['HJ8gAvoobX7', 'rkdl0wji-Xm']
                    },
                    'ryeGYCFZ7X': {
                        'id': 'ryeGYCFZ7X',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': -798,
                        'y': 390,
                        'lines': ['SkMYRF-mQ', 'SybGYAF-77', 'BkHFji-Xm'],
                        'areas': ['HJ8gAvoobX7', 'rkdl0wji-Xm']
                    },
                    'HyXU7iiZ7Q': {
                        'id': 'HyXU7iiZ7Q',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': 100,
                        'y': 209,
                        'lines': ['B1MLQisb7X', 'rkNl0vjjb7X', 'SyHxRDii-77'],
                        'areas': ['SJFP7sibmQ', 'HJ8gAvoobX7', 'SkPlCPosb7Q']
                    },
                    'HyQfYAtb7m': {
                        'id': 'HyQfYAtb7m',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': 706,
                        'y': 390,
                        'lines': ['SJ1vxc-QX', 'rJeJPlcZX7', 'BkadiobQ7'],
                        'areas': ['SkPlCPosb7Q', 'rkdl0wji-Xm']
                    },
                    'Hyrv7jsWQX': {
                        'id': 'Hyrv7jsWQX',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': 100,
                        'y': -144,
                        'lines': ['SyVPmoob7m', 'S1LwmojWQm', 'rkNl0vjjb7X'],
                        'areas': ['SJFP7sibmQ', 'SkPlCPosb7Q']
                    },
                    'rye8Xosb7m': {
                        'id': 'rye8Xosb7m',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': -290,
                        'y': 209,
                        'lines': ['B1MLQisb7X', 'BkFDssbQ7'],
                        'areas': ['SJFP7sibmQ', 'HJ8gAvoobX7']
                    },
                    'BJxUqCtWX7': {
                        'id': 'BJxUqCtWX7',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': -290,
                        'y': -144,
                        'lines': ['S1U9CKZQm', 'S1LwmojWQm', 'BkFDssbQ7'],
                        'areas': ['SJFP7sibmQ', 'HJ8gAvoobX7']
                    },
                    'r1OEm3t-QX': {
                        'id': 'r1OEm3t-QX',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': -798,
                        'y': 956,
                        'lines': ['ryp8AKZ7m', 'SybGYAF-77'],
                        'areas': ['rkdl0wji-Xm']
                    },
                    'H1R31RtZmX': {
                        'id': 'H1R31RtZmX',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': 706,
                        'y': 956,
                        'lines': ['ryp8AKZ7m', 'rJeJPlcZX7'],
                        'areas': ['rkdl0wji-Xm']
                    },
                    'HJQql_YbQQ': {
                        'id': 'HJQql_YbQQ',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': 706,
                        'y': -144,
                        'lines': ['SJ1vxc-QX', 'SyVPmoob7m'],
                        'areas': ['SkPlCPosb7Q']
                    },
                    'SJgYxuYW7Q': {
                        'id': 'SJgYxuYW7Q',
                        'type': '',
                        'prototype': 'vertices',
                        'name': 'Vertex',
                        'misc': {},
                        'selected': false,
                        'properties': {},
                        'x': -798,
                        'y': -144,
                        'lines': ['SkMYRF-mQ', 'S1U9CKZQm'],
                        'areas': ['HJ8gAvoobX7']
                    }
                },
                'lines': {
                    'BkFDssbQ7': {
                        'id': 'BkFDssbQ7',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['BJxUqCtWX7', 'rye8Xosb7m'],
                        'holes': []
                    },
                    'SyHxRDii-77': {
                        'id': 'SyHxRDii-77',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['HyXU7iiZ7Q', 'SJfgCDjs-XQ'],
                        'holes': []
                    },
                    'rkNl0vjjb7X': {
                        'id': 'rkNl0vjjb7X',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['Hyrv7jsWQX', 'HyXU7iiZ7Q'],
                        'holes': []
                    },
                    'SyVPmoob7m': {
                        'id': 'SyVPmoob7m',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['HJQql_YbQQ', 'Hyrv7jsWQX'],
                        'holes': []
                    },
                    'S1U9CKZQm': {
                        'id': 'S1U9CKZQm',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['SJgYxuYW7Q', 'BJxUqCtWX7'],
                        'holes': []
                    },
                    'SJ1vxc-QX': {
                        'id': 'SJ1vxc-QX',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['HJQql_YbQQ', 'HyQfYAtb7m'],
                        'holes': []
                    },
                    'rJeJPlcZX7': {
                        'id': 'rJeJPlcZX7',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['HyQfYAtb7m', 'H1R31RtZmX'],
                        'holes': []
                    },
                    'S1LwmojWQm': {
                        'id': 'S1LwmojWQm',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['BJxUqCtWX7', 'Hyrv7jsWQX'],
                        'holes': []
                    },
                    'BkadiobQ7': {
                        'id': 'BkadiobQ7',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['SJfgCDjs-XQ', 'HyQfYAtb7m'],
                        'holes': []
                    },
                    'B1MLQisb7X': {
                        'id': 'B1MLQisb7X',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['rye8Xosb7m', 'HyXU7iiZ7Q'],
                        'holes': []
                    },
                    'ryp8AKZ7m': {
                        'id': 'ryp8AKZ7m',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['r1OEm3t-QX', 'H1R31RtZmX'],
                        'holes': []
                    },
                    'SkMYRF-mQ': {
                        'id': 'SkMYRF-mQ',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['SJgYxuYW7Q', 'ryeGYCFZ7X'],
                        'holes': []
                    },
                    'SJlHtsoW77': {
                        'id': 'SJlHtsoW77',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['SymUcCKb7X', 'SJfgCDjs-XQ'],
                        'holes': []
                    },
                    'SybGYAF-77': {
                        'id': 'SybGYAF-77',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['r1OEm3t-QX', 'ryeGYCFZ7X'],
                        'holes': []
                    },
                    'BkHFji-Xm': {
                        'id': 'BkHFji-Xm',
                        'type': 'wall',
                        'prototype': 'lines',
                        'name': 'Wall',
                        'misc': {},
                        'selected': false,
                        'properties': {
                            'height': {'length': 300},
                            'thickness': {'length': 20},
                            'textureA': 'none',
                            'textureB': 'none'
                        },
                        'vertices': ['ryeGYCFZ7X', 'SymUcCKb7X'],
                        'holes': []
                    }
                },
                'holes': {},
                'areas': {
                    'SJFP7sibmQ': {
                        'id': 'SJFP7sibmQ',
                        'type': 'area',
                        'prototype': 'areas',
                        'name': 'Area',
                        'misc': {},
                        'selected': false,
                        'properties': {'patternColor': '#f5f4f4', 'thickness': {'length': 0}, 'texture': 'none'},
                        'vertices': ['HyXU7iiZ7Q', 'rye8Xosb7m', 'BJxUqCtWX7', 'Hyrv7jsWQX'],
                        'holes': []
                    },
                    'HJ8gAvoobX7': {
                        'id': 'HJ8gAvoobX7',
                        'type': 'area',
                        'prototype': 'areas',
                        'name': 'Area',
                        'misc': {},
                        'selected': false,
                        'properties': {'patternColor': '#f5f4f4', 'thickness': {'length': 0}, 'texture': 'none'},
                        'vertices': ['rye8Xosb7m', 'HyXU7iiZ7Q', 'SJfgCDjs-XQ', 'SymUcCKb7X', 'ryeGYCFZ7X', 'SJgYxuYW7Q', 'BJxUqCtWX7'],
                        'holes': []
                    },
                    'SkPlCPosb7Q': {
                        'id': 'SkPlCPosb7Q',
                        'type': 'area',
                        'prototype': 'areas',
                        'name': 'Area',
                        'misc': {},
                        'selected': false,
                        'properties': {'patternColor': '#f5f4f4', 'thickness': {'length': 0}, 'texture': 'none'},
                        'vertices': ['Hyrv7jsWQX', 'HJQql_YbQQ', 'HyQfYAtb7m', 'SJfgCDjs-XQ', 'HyXU7iiZ7Q'],
                        'holes': []
                    },
                    'rkdl0wji-Xm': {
                        'id': 'rkdl0wji-Xm',
                        'type': 'area',
                        'prototype': 'areas',
                        'name': 'Area',
                        'misc': {},
                        'selected': false,
                        'properties': {'patternColor': '#f5f4f4', 'thickness': {'length': 0}, 'texture': 'none'},
                        'vertices': ['ryeGYCFZ7X', 'SymUcCKb7X', 'SJfgCDjs-XQ', 'HyQfYAtb7m', 'H1R31RtZmX', 'r1OEm3t-QX'],
                        'holes': []
                    }
                },
                'items': {},
                'selected': {'vertices': [], 'lines': [], 'holes': [], 'areas': [], 'items': []}
            }
        },
        'guides': {
            'h1': {
                'id': 'h1',
                'type': 'horizontal-streak',
                'properties': {'step': 20, 'colors': ['#808080', '#ddd', '#ddd', '#ddd', '#ddd']}
            },
            'v1': {
                'id': 'v1',
                'type': 'vertical-streak',
                'properties': {'step': 20, 'colors': ['#808080', '#ddd', '#ddd', '#ddd', '#ddd']}
            }
        },
        'selectedLayer': 'layer-1',
        'width': 5000,
        'height': 5000,
        'meta': {}
    },
    objects: [{
        id: 'bedDouble',
        pos: '-5.5 0 1.065',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'desk',
        pos: '-3.5 0 2.9',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'computerScreen',
        pos: '-3.92 1 3.59',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'chairDesk',
        pos: '-5.12 0 3',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'doorway',
        pos: '0.65 -0.15 3.825',
        rot: '0 0 0',
        scl: '0.25 0.25 0.15'
    }, {
        id: 'bookcaseOpen',
        pos: '-3.27 -0.15 3.85',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'bathroomCabinet',
        pos: '-0.90 1.4 1.97',
        rot: '0 0 0',
        scl: '0.25 0.24 0.05'
    }, {
        id: 'bathtub',
        pos: '-0.45 0 -1.4',
        rot: '0 90 0',
        scl: '0.29 0.25 0.25'
    }, {
        id: 'doorway',
        pos: '-2.975 -0.15 0.3',
        rot: '0 90 0',
        scl: '0.25 0.25 0.15'
    }, {
        id: 'bathroomSink',
        pos: '-1.26 1.029 1.347',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'toilet',
        pos: '-2.86 0 -0.12',
        rot: '0 270 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'cabinetBedDrawer',
        pos: '-7.375 0 -0.90',
        rot: '0 180 0',
        scl: '0.5 0.25 0.25'
    }, {
        id: 'televisionModern',
        pos: '-6.75 0.669 -1.15',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'computerKeyboard',
        pos: '-4.12 1 2.9',
        rot: '0 10.75 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'computerMouse',
        pos: '-5 1 3',
        rot: '0 8 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'books',
        pos: '-2.5 0.2 3.4',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'books',
        pos: '-2.7 0.75 3.3',
        rot: '0 5 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'books',
        pos: '-2.35 1.4 3.5',
        rot: '0 -5 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'plantSmall1',
        pos: '-2.9 1.4 3.5',
        rot: '0 -5 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'loungeSofaCorner',
        pos: '-5.5 0.0 6.4',
        rot: '0 270 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'bear',
        pos: '-6.8 1.5 4.3',
        rot: '0 180 0',
        scl: '0.15 0.15 0.15'
    }, {
        id: 'tableCoffee',
        pos: '-6.49 0 9',
        rot: '0 0 0',
        scl: '0.20 0.25 0.15'
    }, {
        id: 'speaker',
        pos: '-7.25 0 9.15',
        rot: '0 -30 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'speaker',
        pos: '-4.95 0 9',
        rot: '0 30 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'televisionModern',
        pos: '-6.25 0.56 9.15',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'rugRounded',
        pos: '5 0 5.75',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'table',
        pos: '4.1 0 6.325',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'chair',
        pos: '3.75 0 7.5',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'chair',
        pos: '2.85 0 7.5',
        rot: '0 0 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'chair',
        pos: '3.25 0 6.3',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'chair',
        pos: '2.35 0 6.3',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'chair',
        pos: '4.2 0 6.65',
        rot: '0 90 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'chair',
        pos: '1.9 0 7.1',
        rot: '0 270 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'doorwayFront',
        pos: '7 0 9.25',
        rot: '0 270 0',
        scl: '0.25 0.235 0.1'
    }, {
        id: 'coatRack',
        pos: '6.7 1.3 6.7',
        rot: '0 90 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'benchCushionLow',
        pos: '6.3 0 4.25',
        rot: '0 90 0',
        scl: '0.5 0.25 0.25'
    }, {
        id: 'loungeChairRelax',
        pos: '-5 0 5.5',
        rot: '0 160 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'doorway',
        pos: '1.4 0 3.96',
        rot: '0 180 0',
        scl: '0.25 0.235 0.15'
    }, {
        id: 'kitchenCabinet',
        pos: '4.9 0 -0.3',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'kitchenCabinetCornerInner',
        pos: '5.9 0 -0.275',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'kitchenSink',
        pos: '5.925 0 -0.275',
        rot: '0 90 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'kitchenStoveElectric',
        pos: '3.9 0 -0.3',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'kitchenCabinet',
        pos: '2.9 0 -0.3',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'kitchenCabinet',
        pos: '1.9 0 -0.3',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'kitchenCabinet',
        pos: '5.925 0 0.725',
        rot: '0 90 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'kitchenFridge',
        pos: '6.3 0 2.725',
        rot: '0 90 0',
        scl: '0.25 0.235 0.25'
    }, {
        id: 'kitchenCabinetUpperDouble',
        pos: '4.9 1.6 -0.925',
        rot: '0 180 0',
        scl: '0.25 0.20 0.25'
    }, {
        id: 'kitchenCabinetUpperDouble',
        pos: '3.9 1.6 -0.925',
        rot: '0 180 0',
        scl: '0.25 0.20 0.25'
    }, {
        id: 'kitchenCabinetUpperDouble',
        pos: '2.9 1.6 -0.925',
        rot: '0 180 0',
        scl: '0.25 0.20 0.25'
    }, {
        id: 'kitchenCabinetUpperDouble',
        pos: '1.9 1.6 -0.925',
        rot: '0 180 0',
        scl: '0.25 0.20 0.25'
    }, {
        id: 'kitchenCabinetUpperDouble',
        pos: '6.55 1.6 0.625',
        rot: '0 90 0',
        scl: '0.25 0.20 0.25'
    }, {
        id: 'kitchenCabinetUpperDouble',
        pos: '6.55 1.6 -0.375',
        rot: '0 90 0',
        scl: '0.25 0.20 0.25'
    }, {
        id: 'kitchenCabinetUpperDouble',
        pos: '6.55 1.6 -1.5',
        rot: '0 90 0',
        scl: '0.275 0.20 0.25'
    }, {
        id: 'kitchenCabinetUpperDouble',
        pos: '5.9 1.6 -0.925',
        rot: '0 180 0',
        scl: '0.275 0.20 0.25'
    }, {
        id: 'kitchenMicrowave',
        pos: '2 1.1 -0.7',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }, {
        id: 'kitchenCoffeeMachine',
        pos: '3.2 1.1 -0.7',
        rot: '0 180 0',
        scl: '0.25 0.25 0.25'
    }]
};
