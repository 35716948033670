import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Table, Grid, Menu, Button, Input, Popup, Modal, } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Models as PlannerModels } from '../vendor/react-planner';
import { appendDesign, setDesigns, deleteDesign } from '../actions';
import { addDesign, getUserIdByEmail, listDesigns, shareDesignWithUser, removeDesign } from '../api';
import MenuBar from './MenuBar';
import { DEMO_JSON } from '../constants';

class DesignsPage extends Component {
    static propTypes = {
        setDesigns: PropTypes.func.isRequired,
        appendDesign: PropTypes.func.isRequired,
        deleteDesign: PropTypes.func.isRequired,
        designs: PropTypes.array,
        history: PropTypes.object,
    };

    state = {
        sharingWithEmail: '',
        sharingWithUserId: null,
        sharingError: null,
    };

    componentDidMount() {
        if (!this.props.designs) {
            listDesigns().then(this.props.setDesigns);
        }
    }

    handleAdd = () => {
        const name = prompt();
        if (!name) return;
        addDesign(name, { walls: new PlannerModels.Scene({ height: 5000, width: 5000 }).toJS(), objects: [] })
            .then(design => {
                this.props.appendDesign(design);
                this.props.history.push(`/designs/${design.design_id}/floorplanner`);
            });
    };

    handleAddDemo = () => {
        const name = prompt();
        if (!name) return;
        addDesign(name, DEMO_JSON)
            .then(design => {
                this.props.appendDesign(design);
                this.props.history.push(`/designs/${design.design_id}/floorplanner`);
            });
    };

    handleChangeShareEmail = e => {
        const email = e.target.value;
        this.setState({
            sharingWithEmail: email,
            sharingWithUserId: null,
            sharingError: null,
        });
        getUserIdByEmail(email).then(
            ({ user_id }) => this.setState({ sharingWithUserId: user_id }),
            () => this.setState({ sharingWithUserId: -1 }),
        );
    };

    handleSubmitShare = design => {
        shareDesignWithUser(design.design_id, this.state.sharingWithUserId).then(
            this.handleEndShare,
            r => this.setState({ sharingError: `${r.status} ${r.statusText}` }),
        );
    };

    handleEndShare = () => {
        this.setState({
            sharingWithEmail: '',
            sharingWithUserId: null,
            sharingError: null,
        });
    };

    render() {
        // todo(interiovr): display more info about designs in this table (see `type Design` in api.js)
        // todo(interiovr): allow deleting designs (using removeDesign() in api.js)
        // todo(interiovr): allow renaming or updating design info (using updateDesignMeta in api.js)
        return (
            <div className="Designs">
                <Helmet>
                    <title>Designs | Interio[V]R</title>
                </Helmet>
                <MenuBar/>
                <Grid divided="vertically">
                    <Grid.Row columns={3}>
                        <Grid.Column/>
                        <Grid.Column>
                            <Table>
                                <Table.Header>
                                    <Table.Row textAlign="center">
                                        <Table.HeaderCell>
                                            {'Designs'}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {(this.props.designs || []).map(design => (
                                        <Table.Row key={design.design_id}>
                                            <Table.Cell>
                                                <Link to={`/designs/${design.design_id}/floorplanner`}>
                                                    {design.name}
                                                </Link>
                                                <Modal
                                                    trigger={
                                                        <Button
                                                            floated="right"
                                                            size="mini"
                                                            compact
                                                            color="red">
                                                            {'Delete'}
                                                        </Button>
                                                    }
                                                    size='small'
                                                    closeIcon
                                                    header='Delete Design'
                                                    content={`Are you sure you want to delete ${design.name}?`}
                                                    actions={[
                                                        'No',
                                                        {
                                                            key: 'yes',
                                                            content: 'Yes',
                                                            positive: true,
                                                            onClick: () => {
                                                                removeDesign(design.design_id);
                                                                this.props.deleteDesign(design.design_id);
                                                            }
                                                        }
                                                    ]}
                                                >
                                                </Modal>
                                                <Popup
                                                    trigger={<Button floated="right" size="mini" compact>{'Share'}</Button>}
                                                    on="click"
                                                    position="bottom right"
                                                    onClose={this.handleEndShare}
                                                >
                                                    <Input
                                                        label="Email"
                                                        size="mini"
                                                        value={this.state.sharingWithEmail}
                                                        error={this.state.sharingWithUserId === -1}
                                                        onChange={this.handleChangeShareEmail}
                                                        action={
                                                            <Button
                                                                size="mini"
                                                                compact
                                                                disabled={!(this.state.sharingWithUserId > -1) || this.state.sharingError}
                                                                color={this.state.sharingError ? 'red' : null}
                                                                onClick={() => this.handleSubmitShare(design)}
                                                            >
                                                                {'Confirm'}
                                                            </Button>
                                                        }
                                                    />
                                                </Popup>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            <Menu floated='right'>
                                                <Menu.Item onClick={this.handleAdd}>
                                                    {'New Design'}
                                                </Menu.Item>
                                                <Menu.Item onClick={this.handleAddDemo}>
                                                    {'New Demo'}
                                                </Menu.Item>
                                            </Menu>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </Grid.Column>
                        <Grid.Column/>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default connect(
    state => ({
        designs: state.designs.designs,
    }),
    {
        setDesigns,
        appendDesign,
        deleteDesign,
    }
)(DesignsPage);
