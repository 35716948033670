import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Logo from './Logo';

import './MenuBar.css';


class MenuBar extends Component {

    static propTypes = {
        user: PropTypes.shape({
            name: PropTypes.string.isRequired,
        })
    };

    getMenuBarButtons() {
        if (this.props.user) {
            return (
                <div style={{'display': 'inherit'}}>
                    <Menu.Item>
                        {'Welcome '}{this.props.user.name}{'!'}
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="/designs">
                            <Button basic>{'Designs'}</Button>
                        </Link>
                        <Link to="/objects">
                            <Button basic>{'Objects'}</Button>
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="/signout">
                            <Button basic>{'Sign Out'}</Button>
                        </Link>
                    </Menu.Item>
                </div>
            );
        } else {
            return (
                <Menu.Item>
                    <Link to="/signin">
                        <Button basic>Sign In</Button>
                    </Link>
                </Menu.Item>
            );
        }
    }

    render() {
        return (
            <Menu secondary className="MenuBar">
                <Link to="/">
                    <Logo />
                </Link>
                <Menu.Menu position="right">
                    {this.getMenuBarButtons()}
                </Menu.Menu>
            </Menu>
        );
    }
}

export default connect(
    state => ({
        user: state.auth.user,
    }),
)(MenuBar);
