/* eslint-disable no-console */

function api(method, path, body, { raw = false } = {}) {
    const response = fetch(path, {
        method,
        headers: raw ?
            { 'Accept': 'application/json' } :
            { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: raw ? body : JSON.stringify(body),
        credentials: 'include',
    });
    return {
        json: () => response.then(r => {
            if (r.ok) return r.json();
            else throw r;
        }),
        text: () => response.then(r => {
            if (r.ok) return r.text();
            else throw r;
        }),
        then: (onResolve, onReject) => response.then(r => {
            if (r.ok) return r;
            else throw r;
        }).then(onResolve, onReject),
        catch: onReject => response.then(r => {
            if (r.ok) return r;
            else throw r;
        }).then(undefined, onReject),
    };
}

function websocket(path, onMessage) {
    const proto = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const host = window.location.host;
    const ws = new WebSocket(`${proto}//${host}${path}`);
    ws.onmessage = e => onMessage(JSON.parse(e.data));
    return {
        send: obj => {
            try { ws.send(JSON.stringify(obj)); }
            catch (e) { console.warn('Failed to send websocket message:', e); }
        },
        close: () => {
            try { ws.close(); }
            catch (e) { console.warn('Failed to close websocket:', e); }
        },
    };
}

export const signupPassword = (name, email, password) => api('POST', '/api/signup/password', { name, email, password });
export const signinPassword = (email, password) => api('POST', '/api/signin/password', { email, password });
export const signout = () => api('POST', '/api/signout');

/*
type User = {
    name: string,
};

type UserId = {
    user_id: int,
};
*/

// todo(interiovr): allow changing user name (updateUserInfo below)
export const getUserInfo = () => api('GET', '/api/user').json(/* -> User */);
export const updateUserInfo = ({ name }) => api('PATCH', '/api/user', { name });
export const getUserIdByEmail = (email) => api('POST', '/api/user/byemail', { email }).json(/* -> UserId */);

/*
// Metadata about a design.
type Design = {
    design_id: int,
    name: string,
    time_created: string,
    last_mode: string,
    last_position_x: int,
    last_position_y: int,
    last_position_z: int,
};

// JSON schema for saving a design.
type DesignSchema = {
    walls: typeof PlannerModels.Scene().toJS(), // react-planner internal representation
    objects: [{
        id: 'presetName' | int, // string -> preset object; int -> user object id
        pos: '0 0 0',
        rot: '0 0 0',
        scl: '1 1 1',
    }]
};
*/

export const addDesign = (name, json) => api('POST', `/api/designs/new/${name}`, json).json(/* -> Design */);
export const listDesigns = () => api('GET', '/api/designs').json(/* -> Design[] */);
export const getDesignMeta = (id) => api('GET', `/api/designs/${id}`).json(/* -> Design */); // fyi listDesigns also returns all this info
export const getDesignJson = (id) => api('GET', `/api/designs/${id}/json`).json(/* -> DesignSchema */);
export const designUpdatesSocket = (id, onMessage) => websocket(`/api/ws/designs/${id}/json`, onMessage);
export const updateDesignMeta = (id, { name, last_mode, last_position_x, last_position_y, last_position_z }) =>
    api('PATCH', `/api/designs/${id}`, { name, last_mode, last_position_x, last_position_y, last_position_z });
export const updateDesignJson = (id, json) => api('PATCH', `/api/designs/${id}/json`, json);
export const shareDesignWithUser = (id, userId) => api('PATCH', `/api/designs/${id}/share`, { user_id: userId });
export const removeDesign = (id) => api('DELETE', `/api/designs/${id}`);

/*
// Metadata about an object.
type Object = {
    object_id: int,
    name: string,
};
*/

export const addObject = (name, obj, mtl) => api('POST', `/api/objects/new/${name}`, { obj, mtl }).json(/* -> Object */);
export const listObjects = () => api('GET', '/api/objects').json(/* -> Object[] */);
export const getObjectMeta = (id) => api('GET', `/api/objects/${id}`).json(/* -> Object */); // fyi listObjects also returns all this info
export const getObjectObj = (id) => api('GET', `/api/objects/${id}/obj`).text(/* -> <raw content> */);
export const getObjectMtl = (id) => api('GET', `/api/objects/${id}/mtl`).text(/* -> <raw content> */);
export const updateObjectMeta = (id, { name }) => api('PATCH', `/api/objects/${id}`, { name });
export const updateObjectObj = (id, obj) => api('PATCH', `/api/objects/${id}/obj`, obj, { raw: true });
export const updateObjectMtl = (id, mtl) => api('PATCH', `/api/objects/${id}/mtl`, mtl, { raw: true });
export const removeObject = (id) => api('DELETE', `/api/objects/${id}`);
