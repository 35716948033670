import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Measure from 'react-measure';
import MyCatalog from '../catalog/mycatalog';
import MenuBar from './MenuBar';

import { ReactPlanner, Plugins as PlannerPlugins } from '../vendor/react-planner';
import { setDesignJson, loadWallsIntoPlannerState } from '../actions';

import './FloorPlannerPage.css';
import { updateDesignJson } from '../api';


class FloorPlannerPage extends Component {

    static propTypes = {
        match: PropTypes.object,
        history: PropTypes.object,
        designJson: PropTypes.object,
        setDesignJson: PropTypes.func,
        loadWallsIntoPlannerState: PropTypes.func,
    };

    componentDidMount() {
        this.props.loadWallsIntoPlannerState(this.props.designJson[this.props.match.params.id].walls);
    }

    handleExport = sceneJson => {
        const updatedDesign = {
            ...this.props.designJson[this.props.match.params.id],
            walls: sceneJson,
        };
        updateDesignJson(this.props.match.params.id, updatedDesign)
            .then(() => {
                this.props.setDesignJson(this.props.match.params.id, updatedDesign);
                this.props.history.push('./workspace');
            });
    };

    render() {
        let plugins = [
            PlannerPlugins.Keyboard()
        ];
        return (
            <div className="FloorPlannerPage">
                <Helmet>
                    <title>Floor Planner | Interio[V]R</title>
                </Helmet>
                <MenuBar/>
                <Measure>
                    {({ measureRef, contentRect }) => (
                        <div ref={measureRef} className="FloorPlannerWrapper">
                            {contentRect.entry.width ?
                                <ReactPlanner
                                    catalog={MyCatalog}
                                    width={contentRect.entry.width}
                                    height={contentRect.entry.height}
                                    plugins={plugins}
                                    stateExtractor={state => state.floorplanner}
                                    onSceneExport={this.handleExport}
                                /> :
                                null
                            }
                        </div>
                    )}
                </Measure>
            </div>
        );
    }
}

export default connect(
    state => ({
        designJson: state.designs.json,
    }),
    {
        loadWallsIntoPlannerState,
        setDesignJson
    }
)(FloorPlannerPage);
