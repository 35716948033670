import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Menu, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import 'aframe';
import 'aframe-orbit-controls';
import 'aframe-room-component';
import '../vendor/aframe-extras';
import '../vendor/aframe-super-hands-component';

import './InteriorEditingScene.css';
import woodPattern from '../assets/textures/light_fine_wood.jpg';
import { presetModels } from '../assets/models';
import { updateDesignJson, designUpdatesSocket, listObjects } from '../api';
import { setDesignJson, setObjects } from '../actions';
import { updateIndex } from '../utils';

const assets = (
    <a-assets>
        <img id="woodPattern" src={woodPattern} alt="woodPattern" />
        <a-mixin id='draggable' hoverable draggable droppable stretchable grabbable />
        {presetModels.map(({name, obj}) => (
            <a-asset-item key={name} id={`preset-${name}-obj`} src={obj}/>
        ))}
        {presetModels.map(({name, mtl}) => (
            <a-asset-item key={name} id={`preset-${name}-mtl`} src={mtl}/>
        ))}
    </a-assets>
);

class InteriorEditingScene extends Component {

    static propTypes = {
        demoJson: PropTypes.object,
        designJson: PropTypes.object,
        match: PropTypes.object,
        setDesignJson: PropTypes.func,
        setObjects: PropTypes.func,
        objects: PropTypes.array,
    };

    state = {
        draggingObjects: {},
        inEditingMode: false,
        isFirstPerson: false,
        displayStats: false,
        selectedFurniture: ''
    };

    cursor = React.createRef();
    socket = null;
    furnitureDropDownOptions = presetModels.map(({name, nickname}) => ({
        key: name,
        value: name,
        text: nickname
    }));


    componentDidMount() {
        if (!this.props.objects) {
            listObjects().then(this.props.setObjects);
        }
        this.socket = designUpdatesSocket(this.props.match.params.id, this.handleUpdateMessage);
        document.addEventListener('keyup', this.handleAddFurniture);
    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
        document.removeEventListener('keyup', this.handleAddFurniture);
    }

    handleAddFurniture = e => {
        // Space button
        if (e.keyCode !== 32 || !this.state.selectedFurniture || !this.cursor.current.object3D) return;

        const { x, y, z } = this.cursor.current.object3D.getWorldPosition();
        // Allow furniture additional in demo but don't save it
        const design = this.props.demoJson || this.props.designJson[this.props.match.params.id];
        const newFurniture = {
            id: this.state.selectedFurniture,
            pos: `${x} ${y} ${z}`,
            rot: '0 0 0',
            scl: '0.25 0.25 0.25'
        };
        const updatedDesign = {
            ...design,
            objects: [...design.objects, newFurniture]
        };

        if (!this.props.demoJson) {
            updateDesignJson(this.props.match.params.id, updatedDesign);
            this.socket.send({
                type: 'drop-entity',
                data: {
                    idx: design.objects.length,
                    updatedDesign,
                },
            });
        }

        this.props.setDesignJson(this.props.match.params.id, updatedDesign);
    };

    handleUpdateMessage = msg => {
        switch (msg.type) {
        case 'move-entity':
            this.setState(state => ({
                draggingObjects: {
                    ...state.draggingObjects,
                    [msg.data.idx]: msg.data,
                }
            }));
            break;
        case 'drop-entity':
            this.setState(state => ({
                draggingObjects: {
                    ...state.draggingObjects,
                    [msg.data.idx]: null,
                }
            }));
            this.props.setDesignJson(this.props.match.params.id, msg.data.updatedDesign);
            break;
        default:
            throw new Error(`Unknown message type: ${msg.type}`);
        }
    };

    handleMove = e => {
        if (this.props.demoJson) return;

        if (e.detail.name !== 'position') return;
        if (!e.target.is('grabbed')) return;

        const idx = parseInt(e.target.getAttribute('index'), 10);
        const pos = e.target.getAttribute('position');
        const rot = e.target.getAttribute('rotation');

        this.socket.send({
            type: 'move-entity',
            data: {
                idx,
                pos: `${pos.x} ${pos.y} ${pos.z}`,
                rot: `${rot.x} ${rot.y} ${rot.z}`,
            }
        });
    };

    // save movement to server and redux state
    handleGrabEnd = e => {
        if (this.props.demoJson) return;

        const idx = parseInt(e.target.getAttribute('index'), 10);
        const pos = e.target.getAttribute('position');
        const rot = e.target.getAttribute('rotation');

        const design = this.props.designJson[this.props.match.params.id];
        const objects = design.objects;
        const updatedDesign = {
            ...design,
            objects: updateIndex(objects, idx, object => ({
                ...object,
                pos: `${pos.x} ${pos.y} ${pos.z}`,
                rot: `${rot.x} ${rot.y} ${rot.z}`,
            })),
        };

        // save to server
        updateDesignJson(this.props.match.params.id, updatedDesign);
        this.socket.send({
            type: 'drop-entity',
            data: {
                idx,
                updatedDesign,
            },
        });
        // update redux state
        this.props.setDesignJson(this.props.match.params.id, updatedDesign);
    };

    registerMovableEntity = ele => {
        if (ele) {
            ele.removeEventListener('grab-end', this.handleGrabEnd);
            ele.addEventListener('grab-end', this.handleGrabEnd);
            ele.addEventListener('componentchanged', this.handleMove);
        }
    };

    render3DInteriorSpace() {
        const { walls, objects } = this.props.demoJson || this.props.designJson[this.props.match.params.id];
        const { draggingObjects } = this.state;

        const wallGroups = Object.values(walls.layers)
            .map(layer => {
                const vertices = new Map(
                    Object.entries(layer.vertices)
                        // convert centimeters to meters for x/y coords
                        .map(([k, v]) => [k, {x: v.x / 100, y: v.y / 100}])
                );
                return Object.values(layer.areas).map(area => (
                    area.vertices.map(v => vertices.get(v))
                ));
            })
            .reduce((a, b) => a.concat(b), []);

        // todo(interiovr): load and save last_position_x etc. of design (see `type Design` and updateDesignMeta in api.js)
        // currently the camera always starts at the edge of the first wall
        const initialPosition = wallGroups[0][0];

        return (
            <a-scene stats={this.state.displayStats}>
                {this.renderMenuBar()}
                {assets}
                {this.state.isFirstPerson ? (
                    // key used to ensure element is unmounted and rotation of third person camera
                    // is not preserved when changing modes
                    <a-entity
                        key="first-person"
                        position={`${initialPosition.x} 1 ${initialPosition.y}`}
                        progressive-controls="objects: .object; maxLevel: point"
                        movement-controls="speed: 0.1"
                    >
                        <a-camera>
                            <a-entity id="cursor" position="0 0 -4" ref={this.cursor}></a-entity>
                        </a-camera>
                    </a-entity>
                ) : (
                    <a-entity
                        key="third-person"
                        position={`${initialPosition.x} 10 ${initialPosition.y}`}
                        rotation="0 -180 0"
                    >
                        <a-camera
                            rotation="0 0 0"
                            orbit-controls={`
                                minDistance: 0.5;
                                maxDistance: 180;
                                maxPolarAngle: 45;
                                minPolarAngle: 45;
                                minAzimuthAngle: 0;
                                minAzimuthAngle: 180;
                                enableRotate: false;
                                enablePan: false;
                            `}
                        />
                    </a-entity>
                )}

                <a-sky color="#76939E"/>

                {wallGroups.map((vertices, i) => (
                    <rw-room key={i} material="color:#866">
                        <rw-floor position="0 0 0" material="src: #woodPattern; repeat: 0.5 0.5" />
                        {vertices.map(({x, y}, i) => (
                            <rw-wall key={i} position={`${x} 0 ${y}`}/>
                        ))}
                    </rw-room>
                ))}
                {objects.map(({ id, pos, rot, scl }, i) => {
                    const otherUserDragging = draggingObjects[i];

                    const isCustomUserObject = typeof id === 'number';
                    const obj = isCustomUserObject ? `obj: /api/objects/${id}/obj` : `obj: #preset-${id}-obj`;
                    const mtl = isCustomUserObject ? `mtl: /api/objects/${id}/mtl` : `mtl: #preset-${id}-mtl`;

                    return (
                        <a-entity
                            class="object"
                            key={otherUserDragging ? `drag-${i}` : i}
                            obj-model={`${obj}; ${otherUserDragging ? '' : mtl}`}
                            scale={scl}
                            position={otherUserDragging ? otherUserDragging.pos : pos}
                            rotation={otherUserDragging ? otherUserDragging.rot : rot}
                            mixin={otherUserDragging ? null : 'draggable'}
                            material={otherUserDragging ? 'wireframe: true' : null}
                            index={i}
                            ref={this.registerMovableEntity}
                        />
                    );
                })}
            </a-scene>
        );
    }

    renderMenuBar() {
        const userDropDownOptions = (this.props.objects || []).map(obj => ({
            key: obj.object_id,
            value: obj.object_id,
            text: obj.name,
        }));

        return (
            <Menu secondary>
                <Menu.Menu position='right'>
                    {this.state.isFirstPerson ? (
                        <Menu.Item name='Furniture'
                            style={{ padding: 0 }}>
                            <Dropdown
                                placeholder='Search Furniture'
                                search
                                selection
                                options={[...userDropDownOptions, ...this.furnitureDropDownOptions]}
                                onChange={(e, data) => this.setState({ selectedFurniture: data.value })} />
                        </Menu.Item>) : null}

                    <Menu.Item name='view' onClick={() => this.setState({ isFirstPerson: !this.state.isFirstPerson })}>
                        <Icon name={this.state.isFirstPerson ? 'earlybirds' : 'blind'} />
                        {this.state.isFirstPerson ? ' Third Person' : ' First Person'}
                    </Menu.Item>
                    <Menu.Item name='stats' onClick={() => this.setState({ displayStats: !this.state.displayStats })}>
                        <Icon name={this.state.displayStats ? 'eye slash' : 'eye'} />
                        {' Stats'}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    }

    render() {
        return (
            <div className="InteriorEditingScene">
                {this.render3DInteriorSpace()}
            </div>
        );
    }
}

export default connect(
    state => ({
        designJson: state.designs.json,
        objects: state.objects.objects,
    }),
    {
        setDesignJson,
        setObjects,
    }
)(InteriorEditingScene);
