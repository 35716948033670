import bathroomCabinet_mtl from './3d/bathroomCabinet.mtl';
import bathroomCabinet_obj from './3d/bathroomCabinet.obj';
import bathroomMirror_mtl from './3d/bathroomMirror.mtl';
import bathroomMirror_obj from './3d/bathroomMirror.obj';
import bathroomSink_mtl from './3d/bathroomSink.mtl';
import bathroomSink_obj from './3d/bathroomSink.obj';
import bathtub_mtl from './3d/bathtub.mtl';
import bathtub_obj from './3d/bathtub.obj';
import bear_mtl from './3d/bear.mtl';
import bear_obj from './3d/bear.obj';
import bedBunk_mtl from './3d/bedBunk.mtl';
import bedBunk_obj from './3d/bedBunk.obj';
import bedDouble_mtl from './3d/bedDouble.mtl';
import bedDouble_obj from './3d/bedDouble.obj';
import bedSingle_mtl from './3d/bedSingle.mtl';
import bedSingle_obj from './3d/bedSingle.obj';
import bench_mtl from './3d/bench.mtl';
import bench_obj from './3d/bench.obj';
import benchCushion_mtl from './3d/benchCushion.mtl';
import benchCushion_obj from './3d/benchCushion.obj';
import benchCushionLow_mtl from './3d/benchCushionLow.mtl';
import benchCushionLow_obj from './3d/benchCushionLow.obj';
import bookcaseClosed_mtl from './3d/bookcaseClosed.mtl';
import bookcaseClosed_obj from './3d/bookcaseClosed.obj';
import bookcaseClosedDoors_mtl from './3d/bookcaseClosedDoors.mtl';
import bookcaseClosedDoors_obj from './3d/bookcaseClosedDoors.obj';
import bookcaseClosedWide_mtl from './3d/bookcaseClosedWide.mtl';
import bookcaseClosedWide_obj from './3d/bookcaseClosedWide.obj';
import bookcaseOpen_mtl from './3d/bookcaseOpen.mtl';
import bookcaseOpen_obj from './3d/bookcaseOpen.obj';
import bookcaseOpenLow_mtl from './3d/bookcaseOpenLow.mtl';
import bookcaseOpenLow_obj from './3d/bookcaseOpenLow.obj';
import books_mtl from './3d/books.mtl';
import books_obj from './3d/books.obj';
import cabinetBed_mtl from './3d/cabinetBed.mtl';
import cabinetBed_obj from './3d/cabinetBed.obj';
import cabinetBedDrawer_mtl from './3d/cabinetBedDrawer.mtl';
import cabinetBedDrawer_obj from './3d/cabinetBedDrawer.obj';
import cabinetBedDrawerTable_mtl from './3d/cabinetBedDrawerTable.mtl';
import cabinetBedDrawerTable_obj from './3d/cabinetBedDrawerTable.obj';
import cabinetTelevision_mtl from './3d/cabinetTelevision.mtl';
import cabinetTelevision_obj from './3d/cabinetTelevision.obj';
import cabinetTelevisionDoors_mtl from './3d/cabinetTelevisionDoors.mtl';
import cabinetTelevisionDoors_obj from './3d/cabinetTelevisionDoors.obj';
import cardboardBoxClosed_mtl from './3d/cardboardBoxClosed.mtl';
import cardboardBoxClosed_obj from './3d/cardboardBoxClosed.obj';
import cardboardBoxOpen_mtl from './3d/cardboardBoxOpen.mtl';
import cardboardBoxOpen_obj from './3d/cardboardBoxOpen.obj';
import ceilingFan_mtl from './3d/ceilingFan.mtl';
import ceilingFan_obj from './3d/ceilingFan.obj';
import chair_mtl from './3d/chair.mtl';
import chair_obj from './3d/chair.obj';
import chairCushion_mtl from './3d/chairCushion.mtl';
import chairCushion_obj from './3d/chairCushion.obj';
import chairDesk_mtl from './3d/chairDesk.mtl';
import chairDesk_obj from './3d/chairDesk.obj';
import chairRounded_mtl from './3d/chairRounded.mtl';
import chairRounded_obj from './3d/chairRounded.obj';
import coatRack_mtl from './3d/coatRack.mtl';
import coatRack_obj from './3d/coatRack.obj';
import coatRackStanding_mtl from './3d/coatRackStanding.mtl';
import coatRackStanding_obj from './3d/coatRackStanding.obj';
import computerKeyboard_mtl from './3d/computerKeyboard.mtl';
import computerKeyboard_obj from './3d/computerKeyboard.obj';
import computerMouse_mtl from './3d/computerMouse.mtl';
import computerMouse_obj from './3d/computerMouse.obj';
import computerScreen_mtl from './3d/computerScreen.mtl';
import computerScreen_obj from './3d/computerScreen.obj';
import desk_mtl from './3d/desk.mtl';
import desk_obj from './3d/desk.obj';
import deskCorner_mtl from './3d/deskCorner.mtl';
import deskCorner_obj from './3d/deskCorner.obj';
import doorway_mtl from './3d/doorway.mtl';
import doorway_obj from './3d/doorway.obj';
import doorwayFront_mtl from './3d/doorwayFront.mtl';
import doorwayFront_obj from './3d/doorwayFront.obj';
import doorwayOpen_mtl from './3d/doorwayOpen.mtl';
import doorwayOpen_obj from './3d/doorwayOpen.obj';
import dryer_mtl from './3d/dryer.mtl';
import dryer_obj from './3d/dryer.obj';
import floorCorner_mtl from './3d/floorCorner.mtl';
import floorCorner_obj from './3d/floorCorner.obj';
import floorCornerRound_mtl from './3d/floorCornerRound.mtl';
import floorCornerRound_obj from './3d/floorCornerRound.obj';
import floorFull_mtl from './3d/floorFull.mtl';
import floorFull_obj from './3d/floorFull.obj';
import floorHalf_mtl from './3d/floorHalf.mtl';
import floorHalf_obj from './3d/floorHalf.obj';
import kitchenBar_mtl from './3d/kitchenBar.mtl';
import kitchenBar_obj from './3d/kitchenBar.obj';
import kitchenBarEnd_mtl from './3d/kitchenBarEnd.mtl';
import kitchenBarEnd_obj from './3d/kitchenBarEnd.obj';
import kitchenBlender_mtl from './3d/kitchenBlender.mtl';
import kitchenBlender_obj from './3d/kitchenBlender.obj';
import kitchenCabinet_mtl from './3d/kitchenCabinet.mtl';
import kitchenCabinet_obj from './3d/kitchenCabinet.obj';
import kitchenCabinetCornerInner_mtl from './3d/kitchenCabinetCornerInner.mtl';
import kitchenCabinetCornerInner_obj from './3d/kitchenCabinetCornerInner.obj';
import kitchenCabinetCornerRound_mtl from './3d/kitchenCabinetCornerRound.mtl';
import kitchenCabinetCornerRound_obj from './3d/kitchenCabinetCornerRound.obj';
import kitchenCabinetDrawer_mtl from './3d/kitchenCabinetDrawer.mtl';
import kitchenCabinetDrawer_obj from './3d/kitchenCabinetDrawer.obj';
import kitchenCabinetDrawer1_mtl from './3d/kitchenCabinetDrawer1.mtl';
import kitchenCabinetDrawer1_obj from './3d/kitchenCabinetDrawer1.obj';
import kitchenCabinetUpper_mtl from './3d/kitchenCabinetUpper.mtl';
import kitchenCabinetUpper_obj from './3d/kitchenCabinetUpper.obj';
import kitchenCabinetUpperCorner_mtl from './3d/kitchenCabinetUpperCorner.mtl';
import kitchenCabinetUpperCorner_obj from './3d/kitchenCabinetUpperCorner.obj';
import kitchenCabinetUpperDouble_mtl from './3d/kitchenCabinetUpperDouble.mtl';
import kitchenCabinetUpperDouble_obj from './3d/kitchenCabinetUpperDouble.obj';
import kitchenCabinetUpperLow_mtl from './3d/kitchenCabinetUpperLow.mtl';
import kitchenCabinetUpperLow_obj from './3d/kitchenCabinetUpperLow.obj';
import kitchenCoffeeMachine_mtl from './3d/kitchenCoffeeMachine.mtl';
import kitchenCoffeeMachine_obj from './3d/kitchenCoffeeMachine.obj';
import kitchenFridge_mtl from './3d/kitchenFridge.mtl';
import kitchenFridge_obj from './3d/kitchenFridge.obj';
import kitchenFridgeBuiltIn_mtl from './3d/kitchenFridgeBuiltIn.mtl';
import kitchenFridgeBuiltIn_obj from './3d/kitchenFridgeBuiltIn.obj';
import kitchenFridgeSmall_mtl from './3d/kitchenFridgeSmall.mtl';
import kitchenFridgeSmall_obj from './3d/kitchenFridgeSmall.obj';
import kitchenMicrowave_mtl from './3d/kitchenMicrowave.mtl';
import kitchenMicrowave_obj from './3d/kitchenMicrowave.obj';
import kitchenSink_mtl from './3d/kitchenSink.mtl';
import kitchenSink_obj from './3d/kitchenSink.obj';
import kitchenStove_mtl from './3d/kitchenStove.mtl';
import kitchenStove_obj from './3d/kitchenStove.obj';
import kitchenStoveElectric_mtl from './3d/kitchenStoveElectric.mtl';
import kitchenStoveElectric_obj from './3d/kitchenStoveElectric.obj';
import lampRoundFloor_mtl from './3d/lampRoundFloor.mtl';
import lampRoundFloor_obj from './3d/lampRoundFloor.obj';
import lampRoundTable_mtl from './3d/lampRoundTable.mtl';
import lampRoundTable_obj from './3d/lampRoundTable.obj';
import lampSquareCeiling_mtl from './3d/lampSquareCeiling.mtl';
import lampSquareCeiling_obj from './3d/lampSquareCeiling.obj';
import lampSquareFloor_mtl from './3d/lampSquareFloor.mtl';
import lampSquareFloor_obj from './3d/lampSquareFloor.obj';
import lampSquareTable_mtl from './3d/lampSquareTable.mtl';
import lampSquareTable_obj from './3d/lampSquareTable.obj';
import laptop_mtl from './3d/laptop.mtl';
import laptop_obj from './3d/laptop.obj';
import loungeChair_mtl from './3d/loungeChair.mtl';
import loungeChair_obj from './3d/loungeChair.obj';
import loungeChairRelax_mtl from './3d/loungeChairRelax.mtl';
import loungeChairRelax_obj from './3d/loungeChairRelax.obj';
import loungeSofa_mtl from './3d/loungeSofa.mtl';
import loungeSofa_obj from './3d/loungeSofa.obj';
import loungeSofaCorner_mtl from './3d/loungeSofaCorner.mtl';
import loungeSofaCorner_obj from './3d/loungeSofaCorner.obj';
import loungeSofaLong_mtl from './3d/loungeSofaLong.mtl';
import loungeSofaLong_obj from './3d/loungeSofaLong.obj';
import loungeSofaOttoman_mtl from './3d/loungeSofaOttoman.mtl';
import loungeSofaOttoman_obj from './3d/loungeSofaOttoman.obj';
import paneling_mtl from './3d/paneling.mtl';
import paneling_obj from './3d/paneling.obj';
import pillow_mtl from './3d/pillow.mtl';
import pillow_obj from './3d/pillow.obj';
import pillowLong_mtl from './3d/pillowLong.mtl';
import pillowLong_obj from './3d/pillowLong.obj';
import plantSmall1_mtl from './3d/plantSmall1.mtl';
import plantSmall1_obj from './3d/plantSmall1.obj';
import plantSmall2_mtl from './3d/plantSmall2.mtl';
import plantSmall2_obj from './3d/plantSmall2.obj';
import plantSmall3_mtl from './3d/plantSmall3.mtl';
import plantSmall3_obj from './3d/plantSmall3.obj';
import pottedPlant_mtl from './3d/pottedPlant.mtl';
import pottedPlant_obj from './3d/pottedPlant.obj';
import radio_mtl from './3d/radio.mtl';
import radio_obj from './3d/radio.obj';
import rugRectangle_mtl from './3d/rugRectangle.mtl';
import rugRectangle_obj from './3d/rugRectangle.obj';
import rugRound_mtl from './3d/rugRound.mtl';
import rugRound_obj from './3d/rugRound.obj';
import rugRounded_mtl from './3d/rugRounded.mtl';
import rugRounded_obj from './3d/rugRounded.obj';
import rugSquare_mtl from './3d/rugSquare.mtl';
import rugSquare_obj from './3d/rugSquare.obj';
import shower_mtl from './3d/shower.mtl';
import shower_obj from './3d/shower.obj';
import sideTable_mtl from './3d/sideTable.mtl';
import sideTable_obj from './3d/sideTable.obj';
import sideTableDrawers_mtl from './3d/sideTableDrawers.mtl';
import sideTableDrawers_obj from './3d/sideTableDrawers.obj';
import speaker_mtl from './3d/speaker.mtl';
import speaker_obj from './3d/speaker.obj';
import speakerSmall_mtl from './3d/speakerSmall.mtl';
import speakerSmall_obj from './3d/speakerSmall.obj';
import stoolBar_mtl from './3d/stoolBar.mtl';
import stoolBar_obj from './3d/stoolBar.obj';
import stoolBarSquare_mtl from './3d/stoolBarSquare.mtl';
import stoolBarSquare_obj from './3d/stoolBarSquare.obj';
import table_mtl from './3d/table.mtl';
import table_obj from './3d/table.obj';
import tableCloth_mtl from './3d/tableCloth.mtl';
import tableCloth_obj from './3d/tableCloth.obj';
import tableCoffee_mtl from './3d/tableCoffee.mtl';
import tableCoffee_obj from './3d/tableCoffee.obj';
import tableCoffeeGlass_mtl from './3d/tableCoffeeGlass.mtl';
import tableCoffeeGlass_obj from './3d/tableCoffeeGlass.obj';
import tableCoffeeGlassSquare_mtl from './3d/tableCoffeeGlassSquare.mtl';
import tableCoffeeGlassSquare_obj from './3d/tableCoffeeGlassSquare.obj';
import tableCoffeeSquare_mtl from './3d/tableCoffeeSquare.mtl';
import tableCoffeeSquare_obj from './3d/tableCoffeeSquare.obj';
import tableCross_mtl from './3d/tableCross.mtl';
import tableCross_obj from './3d/tableCross.obj';
import tableCrossCloth_mtl from './3d/tableCrossCloth.mtl';
import tableCrossCloth_obj from './3d/tableCrossCloth.obj';
import tableGlass_mtl from './3d/tableGlass.mtl';
import tableGlass_obj from './3d/tableGlass.obj';
import tableRound_mtl from './3d/tableRound.mtl';
import tableRound_obj from './3d/tableRound.obj';
import televisionAntenna_mtl from './3d/televisionAntenna.mtl';
import televisionAntenna_obj from './3d/televisionAntenna.obj';
import televisionModern_mtl from './3d/televisionModern.mtl';
import televisionModern_obj from './3d/televisionModern.obj';
import televisionVintage_mtl from './3d/televisionVintage.mtl';
import televisionVintage_obj from './3d/televisionVintage.obj';
import toilet_mtl from './3d/toilet.mtl';
import toilet_obj from './3d/toilet.obj';
import trashcan_mtl from './3d/trashcan.mtl';
import trashcan_obj from './3d/trashcan.obj';
import wall_mtl from './3d/wall.mtl';
import wall_obj from './3d/wall.obj';
import wallCorner_mtl from './3d/wallCorner.mtl';
import wallCorner_obj from './3d/wallCorner.obj';
import wallCornerRound_mtl from './3d/wallCornerRound.mtl';
import wallCornerRound_obj from './3d/wallCornerRound.obj';
import wallDoorway_mtl from './3d/wallDoorway.mtl';
import wallDoorway_obj from './3d/wallDoorway.obj';
import wallDoorwayWide_mtl from './3d/wallDoorwayWide.mtl';
import wallDoorwayWide_obj from './3d/wallDoorwayWide.obj';
import wallHalf_mtl from './3d/wallHalf.mtl';
import wallHalf_obj from './3d/wallHalf.obj';
import wallWindow_mtl from './3d/wallWindow.mtl';
import wallWindow_obj from './3d/wallWindow.obj';
import wallWindowSlide_mtl from './3d/wallWindowSlide.mtl';
import wallWindowSlide_obj from './3d/wallWindowSlide.obj';
import washer_mtl from './3d/washer.mtl';
import washer_obj from './3d/washer.obj';
import washerDryerStacked_mtl from './3d/washerDryerStacked.mtl';
import washerDryerStacked_obj from './3d/washerDryerStacked.obj';

import bathroomCabinet_png from './topdown/bathroomCabinet.png';
import bathroomMirror_png from './topdown/bathroomMirror.png';
import bathroomSink_png from './topdown/bathroomSink.png';
import bathtub_png from './topdown/bathtub.png';
import bear_png from './topdown/bear.png';
import bedBunk_png from './topdown/bedBunk.png';
import bedDouble_png from './topdown/bedDouble.png';
import bedSingle_png from './topdown/bedSingle.png';
import bench_png from './topdown/bench.png';
import benchCushion_png from './topdown/benchCushion.png';
import benchCushionLow_png from './topdown/benchCushionLow.png';
import bookcaseClosed_png from './topdown/bookcaseClosed.png';
import bookcaseClosedDoors_png from './topdown/bookcaseClosedDoors.png';
import bookcaseClosedWide_png from './topdown/bookcaseClosedWide.png';
import bookcaseOpen_png from './topdown/bookcaseOpen.png';
import bookcaseOpenLow_png from './topdown/bookcaseOpenLow.png';
import books_png from './topdown/books.png';
import cabinetBed_png from './topdown/cabinetBed.png';
import cabinetBedDrawer_png from './topdown/cabinetBedDrawer.png';
import cabinetBedDrawerTable_png from './topdown/cabinetBedDrawerTable.png';
import cabinetTelevision_png from './topdown/cabinetTelevision.png';
import cabinetTelevisionDoors_png from './topdown/cabinetTelevisionDoors.png';
import cardboardBoxClosed_png from './topdown/cardboardBoxClosed.png';
import cardboardBoxOpen_png from './topdown/cardboardBoxOpen.png';
import ceilingFan_png from './topdown/ceilingFan.png';
import chair_png from './topdown/chair.png';
import chairCushion_png from './topdown/chairCushion.png';
import chairDesk_png from './topdown/chairDesk.png';
import chairRounded_png from './topdown/chairRounded.png';
import coatRack_png from './topdown/coatRack.png';
import coatRackStanding_png from './topdown/coatRackStanding.png';
import computerKeyboard_png from './topdown/computerKeyboard.png';
import computerMouse_png from './topdown/computerMouse.png';
import computerScreen_png from './topdown/computerScreen.png';
import desk_png from './topdown/desk.png';
import deskCorner_png from './topdown/deskCorner.png';
import doorway_png from './topdown/doorway.png';
import doorwayFront_png from './topdown/doorwayFront.png';
import doorwayOpen_png from './topdown/doorwayOpen.png';
import dryer_png from './topdown/dryer.png';
import floorCorner_png from './topdown/floorCorner.png';
import floorCornerRound_png from './topdown/floorCornerRound.png';
import floorFull_png from './topdown/floorFull.png';
import floorHalf_png from './topdown/floorHalf.png';
import kitchenBar_png from './topdown/kitchenBar.png';
import kitchenBarEnd_png from './topdown/kitchenBarEnd.png';
import kitchenBlender_png from './topdown/kitchenBlender.png';
import kitchenCabinet_png from './topdown/kitchenCabinet.png';
import kitchenCabinetCornerInner_png from './topdown/kitchenCabinetCornerInner.png';
import kitchenCabinetCornerRound_png from './topdown/kitchenCabinetCornerRound.png';
import kitchenCabinetDrawer_png from './topdown/kitchenCabinetDrawer.png';
import kitchenCabinetDrawer1_png from './topdown/kitchenCabinetDrawer1.png';
import kitchenCabinetUpper_png from './topdown/kitchenCabinetUpper.png';
import kitchenCabinetUpperCorner_png from './topdown/kitchenCabinetUpperCorner.png';
import kitchenCabinetUpperDouble_png from './topdown/kitchenCabinetUpperDouble.png';
import kitchenCabinetUpperLow_png from './topdown/kitchenCabinetUpperLow.png';
import kitchenCoffeeMachine_png from './topdown/kitchenCoffeeMachine.png';
import kitchenFridge_png from './topdown/kitchenFridge.png';
import kitchenFridgeBuiltIn_png from './topdown/kitchenFridgeBuiltIn.png';
import kitchenFridgeSmall_png from './topdown/kitchenFridgeSmall.png';
import kitchenMicrowave_png from './topdown/kitchenMicrowave.png';
import kitchenSink_png from './topdown/kitchenSink.png';
import kitchenStove_png from './topdown/kitchenStove.png';
import kitchenStoveElectric_png from './topdown/kitchenStoveElectric.png';
import lampRoundFloor_png from './topdown/lampRoundFloor.png';
import lampRoundTable_png from './topdown/lampRoundTable.png';
import lampSquareCeiling_png from './topdown/lampSquareCeiling.png';
import lampSquareFloor_png from './topdown/lampSquareFloor.png';
import lampSquareTable_png from './topdown/lampSquareTable.png';
import laptop_png from './topdown/laptop.png';
import loungeChair_png from './topdown/loungeChair.png';
import loungeChairRelax_png from './topdown/loungeChairRelax.png';
import loungeSofa_png from './topdown/loungeSofa.png';
import loungeSofaCorner_png from './topdown/loungeSofaCorner.png';
import loungeSofaLong_png from './topdown/loungeSofaLong.png';
import loungeSofaOttoman_png from './topdown/loungeSofaOttoman.png';
import paneling_png from './topdown/paneling.png';
import pillow_png from './topdown/pillow.png';
import pillowLong_png from './topdown/pillowLong.png';
import plantSmall1_png from './topdown/plantSmall1.png';
import plantSmall2_png from './topdown/plantSmall2.png';
import plantSmall3_png from './topdown/plantSmall3.png';
import pottedPlant_png from './topdown/pottedPlant.png';
import radio_png from './topdown/radio.png';
import rugRectangle_png from './topdown/rugRectangle.png';
import rugRound_png from './topdown/rugRound.png';
import rugRounded_png from './topdown/rugRounded.png';
import rugSquare_png from './topdown/rugSquare.png';
import shower_png from './topdown/shower.png';
import sideTable_png from './topdown/sideTable.png';
import sideTableDrawers_png from './topdown/sideTableDrawers.png';
import speaker_png from './topdown/speaker.png';
import speakerSmall_png from './topdown/speakerSmall.png';
import stoolBar_png from './topdown/stoolBar.png';
import stoolBarSquare_png from './topdown/stoolBarSquare.png';
import table_png from './topdown/table.png';
import tableCloth_png from './topdown/tableCloth.png';
import tableCoffee_png from './topdown/tableCoffee.png';
import tableCoffeeGlass_png from './topdown/tableCoffeeGlass.png';
import tableCoffeeGlassSquare_png from './topdown/tableCoffeeGlassSquare.png';
import tableCoffeeSquare_png from './topdown/tableCoffeeSquare.png';
import tableCross_png from './topdown/tableCross.png';
import tableCrossCloth_png from './topdown/tableCrossCloth.png';
import tableGlass_png from './topdown/tableGlass.png';
import tableRound_png from './topdown/tableRound.png';
import televisionAntenna_png from './topdown/televisionAntenna.png';
import televisionModern_png from './topdown/televisionModern.png';
import televisionVintage_png from './topdown/televisionVintage.png';
import toilet_png from './topdown/toilet.png';
import trashcan_png from './topdown/trashcan.png';
import wall_png from './topdown/wall.png';
import wallCorner_png from './topdown/wallCorner.png';
import wallCornerRound_png from './topdown/wallCornerRound.png';
import wallDoorway_png from './topdown/wallDoorway.png';
import wallDoorwayWide_png from './topdown/wallDoorwayWide.png';
import wallHalf_png from './topdown/wallHalf.png';
import wallWindow_png from './topdown/wallWindow.png';
import wallWindowSlide_png from './topdown/wallWindowSlide.png';
import washer_png from './topdown/washer.png';
import washerDryerStacked_png from './topdown/washerDryerStacked.png';

export const presetModels = [
    { name: 'bathroomCabinet', nickname: 'Bathroom Cabinet', obj: bathroomCabinet_obj, mtl: bathroomCabinet_mtl, png: bathroomCabinet_png },
    { name: 'bathroomMirror', nickname: 'Bathroom Mirror', obj: bathroomMirror_obj, mtl: bathroomMirror_mtl, png: bathroomMirror_png },
    { name: 'bathroomSink', nickname: 'Bathroom Sink', obj: bathroomSink_obj, mtl: bathroomSink_mtl, png: bathroomSink_png },
    { name: 'bathtub', nickname: 'Bathtub', obj: bathtub_obj, mtl: bathtub_mtl, png: bathtub_png },
    { name: 'bear', nickname: 'Bear', obj: bear_obj, mtl: bear_mtl, png: bear_png },
    { name: 'bedBunk', nickname: 'Bunk Bed', obj: bedBunk_obj, mtl: bedBunk_mtl, png: bedBunk_png },
    { name: 'bedDouble', nickname: 'Double Bed', obj: bedDouble_obj, mtl: bedDouble_mtl, png: bedDouble_png },
    { name: 'bedSingle', nickname: 'Single Bed', obj: bedSingle_obj, mtl: bedSingle_mtl, png: bedSingle_png },
    { name: 'bench', nickname: 'Bench', obj: bench_obj, mtl: bench_mtl, png: bench_png },
    { name: 'benchCushion', nickname: 'Bench Cushion', obj: benchCushion_obj, mtl: benchCushion_mtl, png: benchCushion_png },
    { name: 'benchCushionLow', nickname: 'Bench Cushion (Low)', obj: benchCushionLow_obj, mtl: benchCushionLow_mtl, png: benchCushionLow_png },
    { name: 'bookcaseClosed', nickname: 'Bookcase (closed)', obj: bookcaseClosed_obj, mtl: bookcaseClosed_mtl, png: bookcaseClosed_png },
    { name: 'bookcaseClosedDoors', nickname: 'Bookcase (ClosedDoors)', obj: bookcaseClosedDoors_obj, mtl: bookcaseClosedDoors_mtl, png: bookcaseClosedDoors_png },
    { name: 'bookcaseClosedWide', nickname: 'Bookcase (Closed Wide)', obj: bookcaseClosedWide_obj, mtl: bookcaseClosedWide_mtl, png: bookcaseClosedWide_png },
    { name: 'bookcaseOpen', nickname: 'Bookcase (Open)', obj: bookcaseOpen_obj, mtl: bookcaseOpen_mtl, png: bookcaseOpen_png },
    { name: 'bookcaseOpenLow', nickname: 'Bookcase (Open Low)', obj: bookcaseOpenLow_obj, mtl: bookcaseOpenLow_mtl, png: bookcaseOpenLow_png },
    { name: 'books', nickname: 'Books', obj: books_obj, mtl: books_mtl, png: books_png },
    { name: 'cabinetBed', nickname: 'Cabinet Bed', obj: cabinetBed_obj, mtl: cabinetBed_mtl, png: cabinetBed_png },
    { name: 'cabinetBedDrawer', nickname: 'Cabinet Bed Drawer', obj: cabinetBedDrawer_obj, mtl: cabinetBedDrawer_mtl, png: cabinetBedDrawer_png },
    { name: 'cabinetBedDrawerTable', nickname: 'Cabinet Bed Drawer Table', obj: cabinetBedDrawerTable_obj, mtl: cabinetBedDrawerTable_mtl, png: cabinetBedDrawerTable_png },
    { name: 'cabinetTelevision', nickname: 'Cabinet Television', obj: cabinetTelevision_obj, mtl: cabinetTelevision_mtl, png: cabinetTelevision_png },
    { name: 'cabinetTelevisionDoors', nickname: 'Cabinet Television Doors', obj: cabinetTelevisionDoors_obj, mtl: cabinetTelevisionDoors_mtl, png: cabinetTelevisionDoors_png },
    { name: 'cardboardBoxClosed', nickname: 'Cardboard Box (Closed)', obj: cardboardBoxClosed_obj, mtl: cardboardBoxClosed_mtl, png: cardboardBoxClosed_png },
    { name: 'cardboardBoxOpen', nickname: 'Cardboard Box (Open)', obj: cardboardBoxOpen_obj, mtl: cardboardBoxOpen_mtl, png: cardboardBoxOpen_png },
    { name: 'ceilingFan', nickname: 'Ceiling Fan', obj: ceilingFan_obj, mtl: ceilingFan_mtl, png: ceilingFan_png },
    { name: 'chair', nickname: 'Chair', obj: chair_obj, mtl: chair_mtl, png: chair_png },
    { name: 'chairCushion', nickname: 'Chair Cushion', obj: chairCushion_obj, mtl: chairCushion_mtl, png: chairCushion_png },
    { name: 'chairDesk', nickname: 'Chair Desk', obj: chairDesk_obj, mtl: chairDesk_mtl, png: chairDesk_png },
    { name: 'chairRounded', nickname: 'Rounded Chair', obj: chairRounded_obj, mtl: chairRounded_mtl, png: chairRounded_png },
    { name: 'coatRack', nickname: 'Coat Rack', obj: coatRack_obj, mtl: coatRack_mtl, png: coatRack_png },
    { name: 'coatRackStanding', nickname: 'Coat Rack (Standing)', obj: coatRackStanding_obj, mtl: coatRackStanding_mtl, png: coatRackStanding_png },
    { name: 'computerKeyboard', nickname: 'Computer Keyboard', obj: computerKeyboard_obj, mtl: computerKeyboard_mtl, png: computerKeyboard_png },
    { name: 'computerMouse', nickname: 'Computer Mouse', obj: computerMouse_obj, mtl: computerMouse_mtl, png: computerMouse_png },
    { name: 'computerScreen', nickname: 'Computer Screen', obj: computerScreen_obj, mtl: computerScreen_mtl, png: computerScreen_png },
    { name: 'desk', nickname: 'Desk', obj: desk_obj, mtl: desk_mtl, png: desk_png },
    { name: 'deskCorner', nickname: 'Desk Corner', obj: deskCorner_obj, mtl: deskCorner_mtl, png: deskCorner_png },
    { name: 'doorway', nickname: 'Doorway', obj: doorway_obj, mtl: doorway_mtl, png: doorway_png },
    { name: 'doorwayFront', nickname: 'Doorway (Front)', obj: doorwayFront_obj, mtl: doorwayFront_mtl, png: doorwayFront_png },
    { name: 'doorwayOpen', nickname: 'Doorway (Open)', obj: doorwayOpen_obj, mtl: doorwayOpen_mtl, png: doorwayOpen_png },
    { name: 'dryer', nickname: 'Dryer', obj: dryer_obj, mtl: dryer_mtl, png: dryer_png },
    { name: 'floorCorner', nickname: 'Floor (Corner)', obj: floorCorner_obj, mtl: floorCorner_mtl, png: floorCorner_png },
    { name: 'floorCornerRound', nickname: 'Floor (Corner Round)', obj: floorCornerRound_obj, mtl: floorCornerRound_mtl, png: floorCornerRound_png },
    { name: 'floorFull', nickname: 'Floor (Full)', obj: floorFull_obj, mtl: floorFull_mtl, png: floorFull_png },
    { name: 'floorHalf', nickname: 'Floor Half', obj: floorHalf_obj, mtl: floorHalf_mtl, png: floorHalf_png },
    { name: 'kitchenBar', nickname: 'Kitchen Bar', obj: kitchenBar_obj, mtl: kitchenBar_mtl, png: kitchenBar_png },
    { name: 'kitchenBarEnd', nickname: 'Kitchen Bar End', obj: kitchenBarEnd_obj, mtl: kitchenBarEnd_mtl, png: kitchenBarEnd_png },
    { name: 'kitchenBlender', nickname: 'Kitchen Blender', obj: kitchenBlender_obj, mtl: kitchenBlender_mtl, png: kitchenBlender_png },
    { name: 'kitchenCabinet', nickname: 'Kitchen Cabinet', obj: kitchenCabinet_obj, mtl: kitchenCabinet_mtl, png: kitchenCabinet_png },
    { name: 'kitchenCabinetCornerInner', nickname: 'Kitchen Cabinet (Corner Inner)', obj: kitchenCabinetCornerInner_obj, mtl: kitchenCabinetCornerInner_mtl, png: kitchenCabinetCornerInner_png },
    { name: 'kitchenCabinetCornerRound', nickname: 'Kitchen Cabinet (Corner Round)', obj: kitchenCabinetCornerRound_obj, mtl: kitchenCabinetCornerRound_mtl, png: kitchenCabinetCornerRound_png },
    { name: 'kitchenCabinetDrawer', nickname: 'Kitchen Cabinet (Drawer)', obj: kitchenCabinetDrawer_obj, mtl: kitchenCabinetDrawer_mtl, png: kitchenCabinetDrawer_png },
    { name: 'kitchenCabinetDrawer1', nickname: 'Kitchen Cabinet (Drawer1)', obj: kitchenCabinetDrawer1_obj, mtl: kitchenCabinetDrawer1_mtl, png: kitchenCabinetDrawer1_png },
    { name: 'kitchenCabinetUpper', nickname: 'Kitchen Cabinet (Upper)', obj: kitchenCabinetUpper_obj, mtl: kitchenCabinetUpper_mtl, png: kitchenCabinetUpper_png },
    { name: 'kitchenCabinetUpperCorner', nickname: 'Kitchen Cabinet (Upper Corner)', obj: kitchenCabinetUpperCorner_obj, mtl: kitchenCabinetUpperCorner_mtl, png: kitchenCabinetUpperCorner_png },
    { name: 'kitchenCabinetUpperDouble', nickname: 'kitchen Cabinet (Upper Double)', obj: kitchenCabinetUpperDouble_obj, mtl: kitchenCabinetUpperDouble_mtl, png: kitchenCabinetUpperDouble_png },
    { name: 'kitchenCabinetUpperLow', nickname: 'Kitchen Cabinet (Upper Low)', obj: kitchenCabinetUpperLow_obj, mtl: kitchenCabinetUpperLow_mtl, png: kitchenCabinetUpperLow_png },
    { name: 'kitchenCoffeeMachine', nickname: 'Kitchen Coffee Machine', obj: kitchenCoffeeMachine_obj, mtl: kitchenCoffeeMachine_mtl, png: kitchenCoffeeMachine_png },
    { name: 'kitchenFridge', nickname: 'Kitchen Fridge', obj: kitchenFridge_obj, mtl: kitchenFridge_mtl, png: kitchenFridge_png },
    { name: 'kitchenFridgeBuiltIn', nickname: 'Kitchen Fridge (Built-In)', obj: kitchenFridgeBuiltIn_obj, mtl: kitchenFridgeBuiltIn_mtl, png: kitchenFridgeBuiltIn_png },
    { name: 'kitchenFridgeSmall', nickname: 'Kitchen Fridge (Small)', obj: kitchenFridgeSmall_obj, mtl: kitchenFridgeSmall_mtl, png: kitchenFridgeSmall_png },
    { name: 'kitchenMicrowave', nickname: 'Kitchen Microwave', obj: kitchenMicrowave_obj, mtl: kitchenMicrowave_mtl, png: kitchenMicrowave_png },
    { name: 'kitchenSink', nickname: 'Kitchen Sink', obj: kitchenSink_obj, mtl: kitchenSink_mtl, png: kitchenSink_png },
    { name: 'kitchenStove', nickname: 'Kitchen Stove', obj: kitchenStove_obj, mtl: kitchenStove_mtl, png: kitchenStove_png },
    { name: 'kitchenStoveElectric', nickname: 'Kitchen Stove Electric', obj: kitchenStoveElectric_obj, mtl: kitchenStoveElectric_mtl, png: kitchenStoveElectric_png },
    { name: 'lampRoundFloor', nickname: 'Lamp Round Floor', obj: lampRoundFloor_obj, mtl: lampRoundFloor_mtl, png: lampRoundFloor_png },
    { name: 'lampRoundTable', nickname: 'Lamp Round Table', obj: lampRoundTable_obj, mtl: lampRoundTable_mtl, png: lampRoundTable_png },
    { name: 'lampSquareCeiling', nickname: 'Lamp Square Ceiling', obj: lampSquareCeiling_obj, mtl: lampSquareCeiling_mtl, png: lampSquareCeiling_png },
    { name: 'lampSquareFloor', nickname: 'Lamp Square Floor', obj: lampSquareFloor_obj, mtl: lampSquareFloor_mtl, png: lampSquareFloor_png },
    { name: 'lampSquareTable', nickname: 'Lamp Square Table', obj: lampSquareTable_obj, mtl: lampSquareTable_mtl, png: lampSquareTable_png },
    { name: 'laptop', nickname: 'Laptop', obj: laptop_obj, mtl: laptop_mtl, png: laptop_png },
    { name: 'loungeChair', nickname: 'Lounge Chair', obj: loungeChair_obj, mtl: loungeChair_mtl, png: loungeChair_png },
    { name: 'loungeChairRelax', nickname: 'Lounge Chair (Relax)', obj: loungeChairRelax_obj, mtl: loungeChairRelax_mtl, png: loungeChairRelax_png },
    { name: 'loungeSofa', nickname: 'Lounge Sofa', obj: loungeSofa_obj, mtl: loungeSofa_mtl, png: loungeSofa_png },
    { name: 'loungeSofaCorner', nickname: 'Lounge Sofa (Corner)', obj: loungeSofaCorner_obj, mtl: loungeSofaCorner_mtl, png: loungeSofaCorner_png },
    { name: 'loungeSofaLong', nickname: 'Lounge Sofa (Long)', obj: loungeSofaLong_obj, mtl: loungeSofaLong_mtl, png: loungeSofaLong_png },
    { name: 'loungeSofaOttoman', nickname: 'Lounge Sofa (Ottoman)', obj: loungeSofaOttoman_obj, mtl: loungeSofaOttoman_mtl, png: loungeSofaOttoman_png },
    { name: 'paneling', nickname: 'Paneling', obj: paneling_obj, mtl: paneling_mtl, png: paneling_png },
    { name: 'pillow', nickname: 'Pillow', obj: pillow_obj, mtl: pillow_mtl, png: pillow_png },
    { name: 'pillowLong', nickname: 'Pillow (Long)', obj: pillowLong_obj, mtl: pillowLong_mtl, png: pillowLong_png },
    { name: 'plantSmall1', nickname: 'Plant (Small1)', obj: plantSmall1_obj, mtl: plantSmall1_mtl, png: plantSmall1_png },
    { name: 'plantSmall2', nickname: 'Plant (Small2)', obj: plantSmall2_obj, mtl: plantSmall2_mtl, png: plantSmall2_png },
    { name: 'plantSmall3', nickname: 'Plant (Small3)', obj: plantSmall3_obj, mtl: plantSmall3_mtl, png: plantSmall3_png },
    { name: 'pottedPlant', nickname: 'Potted Plant', obj: pottedPlant_obj, mtl: pottedPlant_mtl, png: pottedPlant_png },
    { name: 'radio', nickname: 'Radio', obj: radio_obj, mtl: radio_mtl, png: radio_png },
    { name: 'rugRectangle', nickname: 'Rug (Rectangle)', obj: rugRectangle_obj, mtl: rugRectangle_mtl, png: rugRectangle_png },
    { name: 'rugRound', nickname: 'Rug (Round)', obj: rugRound_obj, mtl: rugRound_mtl, png: rugRound_png },
    { name: 'rugRounded', nickname: 'Rug (Rounded)', obj: rugRounded_obj, mtl: rugRounded_mtl, png: rugRounded_png },
    { name: 'rugSquare', nickname: 'Rug (Square)', obj: rugSquare_obj, mtl: rugSquare_mtl, png: rugSquare_png },
    { name: 'shower', nickname: 'Shower', obj: shower_obj, mtl: shower_mtl, png: shower_png },
    { name: 'sideTable', nickname: 'Side Table', obj: sideTable_obj, mtl: sideTable_mtl, png: sideTable_png },
    { name: 'sideTableDrawers', nickname: 'Side Table Drawers', obj: sideTableDrawers_obj, mtl: sideTableDrawers_mtl, png: sideTableDrawers_png },
    { name: 'speaker', nickname: 'Speaker', obj: speaker_obj, mtl: speaker_mtl, png: speaker_png },
    { name: 'speakerSmall', nickname: 'Speaker Small', obj: speakerSmall_obj, mtl: speakerSmall_mtl, png: speakerSmall_png },
    { name: 'stoolBar', nickname: 'Stool Bar', obj: stoolBar_obj, mtl: stoolBar_mtl, png: stoolBar_png },
    { name: 'stoolBarSquare', nickname: 'Stool Bar (Square)', obj: stoolBarSquare_obj, mtl: stoolBarSquare_mtl, png: stoolBarSquare_png },
    { name: 'table', nickname: 'Table', obj: table_obj, mtl: table_mtl, png: table_png },
    { name: 'tableCloth', nickname: 'Table Cloth', obj: tableCloth_obj, mtl: tableCloth_mtl, png: tableCloth_png },
    { name: 'tableCoffee', nickname: 'Coffee Table', obj: tableCoffee_obj, mtl: tableCoffee_mtl, png: tableCoffee_png },
    { name: 'tableCoffeeGlass', nickname: 'Coffee Glass Table', obj: tableCoffeeGlass_obj, mtl: tableCoffeeGlass_mtl, png: tableCoffeeGlass_png },
    { name: 'tableCoffeeGlassSquare', nickname: 'Coffee Glass Table (Square)', obj: tableCoffeeGlassSquare_obj, mtl: tableCoffeeGlassSquare_mtl, png: tableCoffeeGlassSquare_png },
    { name: 'tableCoffeeSquare', nickname: 'Coffee Table (Square)', obj: tableCoffeeSquare_obj, mtl: tableCoffeeSquare_mtl, png: tableCoffeeSquare_png },
    { name: 'tableCross', nickname: 'Table (Cross)', obj: tableCross_obj, mtl: tableCross_mtl, png: tableCross_png },
    { name: 'tableCrossCloth', nickname: 'Table (Cross Cloth)', obj: tableCrossCloth_obj, mtl: tableCrossCloth_mtl, png: tableCrossCloth_png },
    { name: 'tableGlass', nickname: 'Table (Glass)', obj: tableGlass_obj, mtl: tableGlass_mtl, png: tableGlass_png },
    { name: 'tableRound', nickname: 'Table (Round)', obj: tableRound_obj, mtl: tableRound_mtl, png: tableRound_png },
    { name: 'televisionAntenna', nickname: 'Television Antenna', obj: televisionAntenna_obj, mtl: televisionAntenna_mtl, png: televisionAntenna_png },
    { name: 'televisionModern', nickname: 'Television (Modern)', obj: televisionModern_obj, mtl: televisionModern_mtl, png: televisionModern_png },
    { name: 'televisionVintage', nickname: 'Television Vintage', obj: televisionVintage_obj, mtl: televisionVintage_mtl, png: televisionVintage_png },
    { name: 'toilet', nickname: 'Toilet', obj: toilet_obj, mtl: toilet_mtl, png: toilet_png },
    { name: 'trashcan', nickname: 'Trashcan', obj: trashcan_obj, mtl: trashcan_mtl, png: trashcan_png },
    { name: 'wall', nickname: 'Wall', obj: wall_obj, mtl: wall_mtl, png: wall_png },
    { name: 'wallCorner', nickname: 'Wall Corner', obj: wallCorner_obj, mtl: wallCorner_mtl, png: wallCorner_png },
    { name: 'wallCornerRound', nickname: 'Wall Corner (Round)', obj: wallCornerRound_obj, mtl: wallCornerRound_mtl, png: wallCornerRound_png },
    { name: 'wallDoorway', nickname: 'Wall Doorway', obj: wallDoorway_obj, mtl: wallDoorway_mtl, png: wallDoorway_png },
    { name: 'wallDoorwayWide', nickname: 'Wall Doorway Wide', obj: wallDoorwayWide_obj, mtl: wallDoorwayWide_mtl, png: wallDoorwayWide_png },
    { name: 'wallHalf', nickname: 'Wall Half', obj: wallHalf_obj, mtl: wallHalf_mtl, png: wallHalf_png },
    { name: 'wallWindow', nickname: 'Window', obj: wallWindow_obj, mtl: wallWindow_mtl, png: wallWindow_png },
    { name: 'wallWindowSlide', nickname: 'Window Slide', obj: wallWindowSlide_obj, mtl: wallWindowSlide_mtl, png: wallWindowSlide_png },
    { name: 'washer', nickname: 'Washer', obj: washer_obj, mtl: washer_mtl, png: washer_png },
    { name: 'washerDryerStacked', nickname: 'Washer & Dryer', obj: washerDryerStacked_obj, mtl: washerDryerStacked_mtl, png: washerDryerStacked_png },
];
