import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { DEMO_JSON } from '../constants';
import InteriorEditingScene from './InteriorEditingScene';


class DemoPage extends Component {
    render() {
        return (
            <div className="WorkspacePage">
                <Helmet>
                    <title>Demo | Interio[V]R</title>
                </Helmet>
                <InteriorEditingScene demoJson={DEMO_JSON} match={{'params':{'id': 'demo'}}} />
            </div>
        );
    }
}

export default DemoPage;
