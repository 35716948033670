/* eslint-disable */
const tableStyle = {width: '100%', borderSpacing: '2px 0', marginBottom: '2px'};
const firstTdStyle = {width: '6em', textTransform:'capitalize'};

const PropertyStyle = {
  tableStyle,
  firstTdStyle
};

export default PropertyStyle;
