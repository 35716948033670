/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MdSave from 'react-icons/lib/md/save';
import FaConnectdevelop from 'react-icons/lib/fa/connectdevelop';
import ToolbarButton from './toolbar-button';
import { MODE_VIEWING_CATALOG } from '../../constants';
import * as SharedStyle from '../../shared-style';
import { unselectAll } from '../../utils/layer-operations';

const iconTextStyle = {
  fontSize: '19px',
  textDecoration: 'none',
  fontWeight: 'bold',
  margin: '0px',
  userSelect: 'none'
};

const ASIDE_STYLE = {
  backgroundColor: SharedStyle.PRIMARY_COLOR.main,
  padding: '10px'
};

export default class Toolbar extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.state.mode !== nextProps.state.mode ||
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width;
  }

  render() {
    let {
      props: { state, width, height, onSceneExport },
      context: { projectActions, linesActions }
    } = this;

    let mode = state.get('mode');

    return (
      <aside style={{ ...ASIDE_STYLE, maxWidth: width, maxHeight: height }} className='toolbar'>
        <ToolbarButton
          active={[MODE_VIEWING_CATALOG].includes(mode)}
          tooltip={'Draw Walls'}
          onClick={event => linesActions.selectToolDrawingLine('wall')}>
          <FaConnectdevelop />
        </ToolbarButton>
        <ToolbarButton
          active={false}
          tooltip={'Save and view in VR'}
          onClick={event => {
            event.preventDefault();
            let scene = state
              .get('scene')
              .update('layers', layers => layers.map(layer => unselectAll(layer)))
              .toJS();
            onSceneExport(scene);
          }}>
          <MdSave />
        </ToolbarButton>
      </aside>
    )
  }
}

Toolbar.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

Toolbar.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
};
