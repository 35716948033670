import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';

import App from './components/App';
import AuthCheck from './components/AuthCheck';
import LandingPage from './components/LandingPage';
import SignInPage from './components/SignInPage';
import SignUpPage from './components/SignUpPage';
import SignOutPage from './components/SignOutPage';
import WorkspacePage from './components/WorkspacePage';
import FloorPlannerPage from './components/FloorPlannerPage';
import ObjectsPage from './components/ObjectsPage';
import DesignsPage from './components/DesignsPage';
import LoadDesignInfo from './components/LoadDesignInfo';
import DemoPage from './components/DemoPage';

export default function Routes() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <App>
                    <Switch>
                        <Route exact path="/" render={() => <AuthCheck><LandingPage/></AuthCheck>}/>
                        <Route exact path="/signin" component={SignInPage}/>
                        <Route exact path="/signup" component={SignUpPage}/>
                        <Route exact path="/signout" component={SignOutPage}/>
                        <Route exact path="/demo" component={DemoPage}/>
                        <Route render={() => (
                            <AuthCheck required>
                                <Switch>
                                    <Route exact path="/objects" component={ObjectsPage}/>
                                    <Route exact path="/designs" component={DesignsPage}/>
                                    <Route path="/designs/:id" render={({ match }) => (
                                        <LoadDesignInfo match={match}>
                                            <Route exact path="/designs/:id/floorplanner" component={FloorPlannerPage}/>
                                            <Route exact path="/designs/:id/workspace" component={WorkspacePage}/>
                                        </LoadDesignInfo>
                                    )}/>
                                </Switch>
                            </AuthCheck>
                        )}/>
                    </Switch>
                </App>
            </BrowserRouter>
        </Provider>
    );
}
