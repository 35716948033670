import React from 'react';
import ReactDOM from 'react-dom';

import {register as registerServiceWorker} from './frontend/registerServiceWorker';
import Routes from './frontend/routes';

import './index.css';
import '../node_modules/semantic-ui-css/semantic.min.css';

ReactDOM.render(<Routes/>, document.getElementById('root'));

registerServiceWorker();
